import {FC} from 'react';
import {usePageData} from '_metronic/layout/core';
import {MenuItem} from '../MenuItem';

const DefaultHeader: FC = () => {
  const {pageHeaderNavigation} = usePageData();

  return (
    <div
      className='header-menu align-items-stretch'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-600 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold fs-6 my-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        {pageHeaderNavigation &&
          pageHeaderNavigation.length > 0 &&
          pageHeaderNavigation?.map((item, index) => (
            <MenuItem
              title={item.title}
              to={item.path}
              fontIcon={item.fontIcon}
              key={`${item.path}${index}`}
            />
          ))}
      </div>
    </div>
  );
};

export {DefaultHeader};
