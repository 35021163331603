export function handleFormatFileName(name: string) {
  const maxNameLength = 10
  const fileName =name.split('.')
  const extension = fileName.pop()
  let newName = fileName[0]
  if (fileName[0].length > maxNameLength) { 
    newName = fileName[0].slice(0, 12) + '...' +  fileName[0].slice(fileName[0].length - 3)
  } 
  newName += `.${extension}`

  return newName
}
