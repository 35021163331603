export function toCurrencyFields(value: number): string {
  const formattedValue = value?.toFixed(2);
  const valueLabel = `R$ ${formattedValue}`;
  return valueLabel?.replace('.', ',');
}

export function currencyFieldsToRequest(value: string): number {
  const formattedValue = value?.trim()?.replace(',', '.');
  return Number(formattedValue);
}

export function formatMoney(value: number): string {
  const money = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
  return money?.replace('R$', '')?.replace('.', '').trim();
}
