import {useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import {requestPassword} from '../core/_requests';

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de e-mail inválido')
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Email é obrigatório'),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [isCodeSubmitted, setIsCodeSubmitted] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({
    code: '',
    public_key: '',
    private_key: '',
    step: 'send_code',
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false);
            setLoading(false);
            setIsCodeSubmitted(true);
            setChangePasswordData((prev) => ({
              ...prev,
              public_key: values.email,
            }));
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus('Por favor revise os dados enviados');
          });
      }, 1000);
    },
  });

  return (
    <>
      {changePasswordData.step === 'send_code' && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Esqueceu a senha ?</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              Entre com seu email para resetar a senha.
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Desculpe, parece que tivemos algum erro, por favor tente
                novamente.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded d-flex flex-column justify-content-center'>
              <div className='text-primary d-flex flex-row mb-3'>
                Enviamos o código para o seu email. Poderia verificar?
              </div>
              <div className='d-flex flex-row justify-content-center'>
                <Link
                  to={`/auth/confirm-new-password?email=${changePasswordData.public_key}`}
                >
                  <button className='btn btn-outline btn-outline-primary w-100'>
                    Recebi o código
                  </button>
                </Link>
              </div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
              Email
            </label>
            <input
              type='email'
              placeholder='Ex. email@email.com'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>
                {isCodeSubmitted ? 'Reenviar código' : 'Enviar código'}
              </span>
              {loading && (
                <span className='indicator-progress'>
                  Aguarde por favor...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      )}
    </>
  );
}
