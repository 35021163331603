import { QuoteRequest } from "app/models";
import { showVehicleBrands, showVehicleModelsByBrand, showVehicleVersionsByModel } from "app/requests";

type VehicleInfo = {
  plate:string
  brand:string
  model:string
  year:number
  version:string
}

let vehicleInfo:VehicleInfo = {
  plate:'',
  brand:'',
  model:'',
  year:0,
  version:''
}

export const getVehicleInfo = async (quoteRequest:QuoteRequest) => {

  if(quoteRequest?.vehicle_plate){
    return {
      plate: quoteRequest?.vehicle_plate,
      brand:'',
      model:'',
      year:0,
      version:''
    }
  } else {
    const vehicle = await getVehicleDetailedData(quoteRequest);
    return vehicle;
  } 
}

const getVehicleDetailedData = async (quoteRequest:QuoteRequest) => {
  if(quoteRequest?.vehicle_brand_uid){
    const vehicleBrandName = await showVehicleBrands([{
      key:'uid',
      value: quoteRequest?.vehicle_brand_uid
    }])

    vehicleInfo.brand = vehicleBrandName[0]?.name;
    
  }

  if(quoteRequest?.vehicle_model_uid){
    const vehicleModelName = await showVehicleModelsByBrand(
      quoteRequest?.vehicle_brand_uid || '',
      [{
        key: 'uid',
        value: quoteRequest?.vehicle_model_uid
      }]
    )

    vehicleInfo.model = vehicleModelName[0]?.name
  };

  if(quoteRequest?.vehicle_version_uid){
    const vehicleVersionName = await showVehicleVersionsByModel(
      quoteRequest?.vehicle_model_uid || '',
    [
      {
        key: 'uid',
        value: quoteRequest?.vehicle_version_uid
      },
      {
        key: 'model_year',
        value: quoteRequest?.vehicle_model_year || ''
      }
    ]
    )

    vehicleInfo.version = vehicleVersionName[0]?.name
  };

  if(quoteRequest?.vehicle_model_year){
    vehicleInfo.year = quoteRequest?.vehicle_model_year
  };

  return vehicleInfo;
}


export const handleCapturedClientPrice = (pretended_purchase_unit_price: number): number => {
  const percentage = 50;
  const total_percentage = (pretended_purchase_unit_price * percentage) / 100;
  const total_price = pretended_purchase_unit_price + total_percentage;
  return total_price;
};