export const errors = {
  UC_CHECK_ORDER_FOR_GENERATE_TAX_RECEIPT_ERROR_BAD_REQUEST: {
    title: 'Não foi possível gerar Nota Fiscal',
    description:
      'O pedido não está completo. Isso pode ocorrer se o pedido ainda estiver em trânsito ou foi totalmente cancelado. Verifique o status do pedido e aguarde a entrega dos itens pendentes se aplicável.',
  },
  UC_CHECK_ORDER_FOR_GENERATE_TAX_RECEIPT_ERR_ORDER_IS_NOT_COMPLETELY: {
    title: 'Impossivel emitir Nota Fiscal',
    description:
      'Não é possível emitir uma nota fiscal para este pedido, pois ele foi totalmente cancelado no mesmo dia da compra. Não há ações a serem tomadas neste caso. Informe ao usuário que não é possível emitir uma nota fiscal para um pedido que foi cancelado no mesmo dia da compra.',
  },
  UC_CHECK_ORDER_FOR_GENERATE_TAX_RECEIPT_ERR_CUSTOMER_HAS_NO_LEGAL_DETAILS: {
    title: 'Falta de Informações Legais',
    description:
      'Não é possível emitir uma nota fiscal para este pedido devido à falta de informações legais no CNPJ fornecido. Instrua o usuário a regularizar o CNPJ para possibilitar a emissão da nota fiscal.',
  },
  UC_CHECK_ORDER_FOR_GENERATE_TAX_RECEIPT_ERROR_INTERNAL_SERVER_ERROR: {
    title: 'Não matching com Nota do Fornecedor',
    description:
      'Não é possível emitir a nota de saída para este pedido, pois as informações dos itens comprados não estão preenchidas. Verifique se as informações da nota fiscal do fornecedor estejam corretas e correspondam ao pedido. Se persistir, abra um chamado para o preenchimento dos dados informados.',
  },
  UP_PURCHASE_ITEM_INCOMING_PRODUCTS_NOT_MATCHED_CNPJS: {
    title: 'CNPJ do Fornecedor divergente',
    description:
      'O CNPJ do fornecedor é divergente do CNPJ informado. Certifique-se de que a compra foi feita com o fornecedor correto. Se necessário, entre em contato com o departamento de compras para fazer as edições necessárias no pedido.',
  },
  UC_UPDATE_PURCHASE_ITEM_ERR_INVALID_PURCHASE_DATE: {
    title: 'Datas de emissão da nota e compra do item inválidas',
    description:
      'A data de emissão da nota fiscal não pode ser anterior à data da compra do item. Certifique-se de que essa é de fato a nota de origem deste item.',
  },
  UP_PURCHASE_ITEM_INCOMING_PRODUCTS_ALREADY_MATCHED: {
    title: 'O item dessa nota fiscal já está preenchido',
    description:
      'Essa nota fiscal já teve match com outro item comprado. Certifique-se que não há outra nota fiscal em um período de até 8 dias que possa ser o match correto deste item. Caso esta seja a única nota possível, favor subir um Ticket indicando o problema',
  },
  UP_PURCHASE_ITEM_INCOMING_PRODUCTS_NOT_MATCHED_ITEM_CODE: {
    title: 'Código de Produto Incorreto na Nota Fiscal',
    description:
      'Não é possível registrar a nota fiscal devido a um código de produto incorreto. Verifique se o código do item corresponde à compra efetuada na nota fiscal de compra.',
  },
  UP_PURCHASE_ITEM_INCOMING_PRODUCTS_NOT_MATCHED_TAX_RECEPT_NUMBER: {
    title: 'Número da Nota Fiscal Não Encontrado',
    description:
      'A nota fiscal fornecida não foi encontrada. Valide se o número da nota fiscal está correto.',
  },
};
