import axios, {AxiosResponse} from 'axios'
import { OrderItem } from '../models';

const API_URL = process.env.REACT_APP_API_URL
const GET_CANCELED_ITEMS_URL = `${API_URL}/canceled-items`


export const getCanceledItems = (query?: string): Promise<OrderItem[]> => {
  return axios
    .get(`${GET_CANCELED_ITEMS_URL}?${query}`)
    .then((resp: AxiosResponse<OrderItem[]>) => resp.data)
    .catch( e => {
      throw e;
  })
}

export const getCanceledItemByUid = (uid: string): Promise<OrderItem> => {
  return axios
    .get(`${GET_CANCELED_ITEMS_URL}/${uid}`)
    .then((resp: AxiosResponse<OrderItem>) => resp.data)
    .catch( e => {
      throw e;
  })
};
