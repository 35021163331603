import {
  AccountUser,
  AnalyzedApplication,
  AnalyzedAutopartDetails,
  AnalyzedAutopartMedias,
  AnalyzedComponent,
  ItemCategory,
  Factory,
  ReportOptions,
} from 'app/models';
import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export type Engines = {
  code: string;
  name: string;
  settings: string;
  power: string;
  capacity: string;
  id: number;
};

export const saveReport = async (
  body: ReportOptions & {
    autopart_id?: number;
    external_catalog_id?: number;
  }
): Promise<AccountUser> => {
  return axios
    .post(`${API_URL}/autoparts/reports`, body)
    .then((d: AxiosResponse<AccountUser>) => d.data);
};

export const listComponents = async () => {
  return axios
    .get(`${API_URL}/components`)
    .then((d: AxiosResponse<AnalyzedComponent[]>) => d.data);
};

export const deleteMedia = async (id: number) => {
  return axios
    .delete(`${API_URL}/autopart-medias/${id}`)
    .then((d: AxiosResponse<AnalyzedAutopartMedias>) => d.data);
};

export const postImage = async (media: Partial<AnalyzedAutopartMedias>[]) => {
  return axios
    .post(`${API_URL}/autopart-medias`, media)
    .then((d: AxiosResponse<AnalyzedAutopartMedias[]>) => d.data);
};

export const getItems = async (filter: string) => {
  const filterQuery = filter ? `?name=${filter}` : '';
  return axios
    .get(`${API_URL}/items${filterQuery}`)
    .then(
      (d: AxiosResponse<{id: number; code: string; name: string}[]>) => d.data
    );
};

export const saveAutopartMedia = async (userAutopartMediaInfos: {
  autopart_id: number;
  url: string;
  origin: 'SEARCHED_BY_USER' | 'SELECTED_FROM_EXTERNAL_OPTIONS';
}) => {
  return axios
    .post(`${API_URL}/autopart/medias/send`, userAutopartMediaInfos)
    .then((d: AxiosResponse) => d.data);
};

export const deleteApplication = async (id: number) => {
  return axios
    .delete(`${API_URL}/applications/${id}`)
    .then((d: AxiosResponse) => d.data);
};

export const deleteTechnicalSpec = async (id: number) => {
  return axios.delete(`${API_URL}/technical-specs/${id}`).then((d) => d.data);
};

export const updateTechnicalSpec = async (id: number, data: any) => {
  return axios
    .put(`${API_URL}/technical-specs/${id}`, data)
    .then((d) => d.data);
};

export const createTechnicalSpec = async (
  data: {
    property_id: number;
    autopart_id: number;
    value: string;
  }[]
) => {
  return axios.post(`${API_URL}/technical-specs`, data).then((d) => d.data);
};

export const getAutopartById = async (
  id?: number
): Promise<AnalyzedAutopartDetails | undefined> => {
  if (id) {
    const autopart = await axios
      .get(`${API_URL}/autoparts/${id}`)
      .then((d) => d.data);

    return {
      ...autopart,
      applications: autopart.applications.map((application: any) => ({
        ...application,
        ...application.vehicle,
        id: application.id,
      })),
    };
  }
};

export const evaluateSearch = async (evaluation: string, searchUid: string) => {
  return axios
    .put(`${API_URL}/search/${searchUid}/evaluate`, {
      evaluation,
    })
    .then((d) => d.data);
};

export const listProperties = async (filter?: string | undefined) => {
  const filterQuery = filter ? `?name=${filter}` : '';
  return axios.get(`${API_URL}/properties${filterQuery}`).then((d) => d.data);
};

export const assignValidation = async () => {
  return axios.get(`${API_URL}/autopart/queue/choose`).then((d) => d.data);
};

export const listAssignedValidations = async () => {
  return axios.get(`${API_URL}/autopart/queue/list`).then((d) => d.data);
};

export const finishValidation = async (id: number) => {
  return axios
    .put(`${API_URL}/autopart/queue/finish/${id}`)
    .then((d) => d.data);
};

export const giveBackValidation = async (id: number) => {
  return axios
    .put(`${API_URL}/autopart/queue/give-back/${id}`)
    .then((d) => d.data);
};

export const getVehicleBrands = async (searchString: string) => {
  return axios
    .get(`${API_URL}/vehicle-manufacturers?name=${searchString}`)
    .then((d) => d.data);
};

export const getVehicleModelsByBrand = async (
  manufacturerId: number,
  searchString: string
) => {
  return axios
    .get(`${API_URL}/vehicle-model/${manufacturerId}?name=${searchString}`)
    .then((d) => d.data);
};

export const getYearsByModelId = async (modelId: number) => {
  return axios.get(`${API_URL}/vehicle/year/${modelId}`).then((d) => d.data);
};

export const getVersionsByModel = async (
  modelId: number
): Promise<string[]> => {
  return axios.get(`${API_URL}/vehicle/version/${modelId}`).then((d) => d.data);
};
export const getEnginesByModel = async (
  modelId: number,
  initial: number,
  final: number
): Promise<Engines[]> => {
  return axios
    .get(
      `${API_URL}/vehicle-engine/${modelId}?initial=${initial}&final=${final}`
    )
    .then((d) => d.data);
};

export const createApplication = async (
  selectedVehicleData: any
): Promise<AnalyzedApplication[]> => {
  const applications = await axios
    .post(`${API_URL}/applications`, selectedVehicleData)
    .then((d) => d.data);

  return applications.map((application: any) => ({
    ...application,
    ...application.vehicle,
    id: application.id,
  }));
};

export const getSimilars = async (
  id: number,
  isExternalCatalog: boolean,
  clientAccountUid: string,
  search_uid: string
) => {
  const query = `?${
    isExternalCatalog ? 'external_catalog=true' : ''
  }&product_id=${id}&client_account_uid=${clientAccountUid}&search_uid=${search_uid}`;
  return axios.get(`${API_URL}/product/similars${query}`).then((d) => d.data);
};

export const getFactories = async (query?: string): Promise<Factory[]> => {
  try {
    const response = await axios.get(`${API_URL}/factories?${query}`);

    if (response?.data) {
      return response.data;
    }

    throw new Error('no_response_to_get_factories');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCategories = async (
  query?: string
): Promise<ItemCategory[]> => {
  try {
    const response = await axios.get(`${API_URL}/category-tree/item?${query}`);

    if (response?.data) {
      return response.data;
    }

    throw new Error('no_response_to_get_categories');
  } catch (error) {
    console.error(error);
    throw error;
  }
};
