export const paymentMethodOptions: Array<{
  label: string;
  value: string | number;
}> = [
  {
    label: 'Selecione...',
    value: '',
  },
  {
    label: 'Faturado',
    value: 'billed',
  },
  {
    label: 'Pix',
    value: 'pix',
  },
  {
    label: 'Cartão de crédito',
    value: 'credit_card',
  },
  {
    label: 'Transferência bancária',
    value: 'transfer',
  },
  {
    label: 'Crédito de devolução',
    value: 'incident_credit',
  },
  {
    label: 'Crédito de devolução mais faturado',
    value: 'incident_credit_and_billed',
  },
  {
    label: 'Crédito de devolução mais pix',
    value: 'incident_credit_and_pix',
  },
  {
    label: 'Crédito de devolução mais cartão de crédito',
    value: 'incident_credit_and_credit_card',
  },
  {
    label: 'Crédito de devolução mais transferência bancária',
    value: 'incident_credit_and_transfer',
  },
];
