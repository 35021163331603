import React, {useState} from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {getUserByToken, verifyOtp} from '../../core/_requests';
import {useAuthContext} from './context/AuthContext';
import {AuthenticationSteps} from './Interfaces/Auth';
import {useAuth} from '../../core/Auth';

const initialValues = {
  code: '',
};

const loginSchema = Yup.object().shape({
  code: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .required('Código é obrigatório'),
});

function SendCodeOtpForm() {
  const {updateAuthContext, public_key, session} = useAuthContext();
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser} = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        if (!public_key || !session) {
          throw new Error('Chave pública não encontrada');
        }

        const auth = await verifyOtp(public_key, values.code, session);

        saveAuth(auth);
        const {data: user} = await getUserByToken();
        setCurrentUser(user);

        updateAuthContext({
          session,
          authStep: AuthenticationSteps.SEND_OTP_CODE,
        });
        setLoading(true);
      } catch (error) {
        console.log('🚀 ~ onSubmit: ~ error:', error);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className='d-flex flex-column w-100'>
      <div>
        <label>Insira o código enviado para o EMAIL</label>
        <input
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid w-100',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
        />
      </div>
      <div className='mt-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}

export default SendCodeOtpForm;
