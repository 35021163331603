import axios from 'axios'
import {AuthModel,  loggedUserModel, verifyUserAccountBody} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/me`
export const LOGIN_URL = `${API_URL}/auth`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/accounts/forgot-password`
export const VERIFY_USER_ACCOUNT = `${API_URL}/accounts/verify`
export const CONFIRM_PASSWORD_URL = `${API_URL}/accounts/confirm-new-password`
export const OTP_REQUEST = `${API_URL}/otp-auth/initiate`;
export const OTP_VERIFY = `${API_URL}/otp-auth/verify`;

// Server should return AuthModel
export function login(email: string, password: string, origin = 'default') {
  return axios.post<AuthModel[]>(LOGIN_URL, {
    public_key: email,
    private_key: password,
    origin,
  });
}

export function initiateOTP(
  public_key: string,
  otp_methods = ['whatsapp', 'email']
) {
  return axios
    .post<{session: string}>(OTP_REQUEST, {
      public_key,
      otp_methods,
    })
    .then((res) => res.data);
}

export function verifyOtp(
  public_key: string,
  private_key: string,
  session: string
) {
  return axios
    .post<AuthModel[]>(OTP_VERIFY, {
      public_key,
      private_key,
      session,
    })
    .then((res) => res.data);
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(public_key: string) {
  return axios.put<{result: boolean}>(REQUEST_PASSWORD_URL, {
    public_key,
  })
}

export function confirmNewPassword(code: string, public_key: string, private_key: string) {
  return axios.put<{result: boolean}>(CONFIRM_PASSWORD_URL, {
    code,
    public_key,
    private_key
  })
}

export function verifyUserAccount(body: verifyUserAccountBody){
  return axios.post(VERIFY_USER_ACCOUNT, body);
}

export function getUserByToken() {
  return axios.get<loggedUserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
