export enum AuthenticationSteps {
  GET_OTP_CODE = 1,
  SEND_OTP_CODE = 2,
  EXPIRED_OTP_CODE = 3,
  TERMS = 4,
  INTERNAL_AUTH = 5,
  CHOOSE_CLIENT = 6,
  LOADING = 7,
}

export type SendCodeParams = {
  public_key: string;
};

export type SignInData = SendCodeParams & {
  private_key: string;
};

export type SignInParams = SignInData & {
  session: string;
};

export type SignInResponseData = {
  account_name: string;
  account_uid: string;
  kind: string;
  role: string;
  token: string;
};

export type SignInWrongCodeData = {
  otpCorrect: boolean;
  session: string;
};

export type SendCodeResponseData = {
  session: string;
};

interface AuthenticationMethods {
  updateAuthContext: (params: Partial<AuthContextType>) => void;
}

export interface AuthContextState {
  user: any | null;
  allowedAttempts: number;
  isAuthenticated: boolean;
  exceededAttempts: boolean;
  authStep: AuthenticationSteps;
  redirect?: string;
  session?: string;
  public_key?: string;
}

export type AuthContextType = AuthenticationMethods & AuthContextState;
