import {FC, MouseEvent} from 'react';
import {getFullNameInitials} from '../../utils';

type Props = {
  name: string;
  mainWidth?: number;
  bgColor?: string;
  gradientBgColor?: String[];
  avatarWidth?: number;
  avatarTextColor?: string;
  avatarTextTypology?: string;
  textTypology?: string;
  hideFullName?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

const Avatar: FC<Props> = ({
  name,
  mainWidth = 400,
  bgColor = 'primary',
  gradientBgColor,
  avatarWidth = 55,
  avatarTextColor = 'inverse-primary',
  avatarTextTypology = '2',
  textTypology = '4',
  hideFullName = false,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={Boolean(onClick) ? 'cursor-pointer' : ''}>
      {!name && (
        <div
          className={`overflow-hidden mw-${mainWidth}px d-flex align-items-center`}
        >
          <div
            className={`symbol symbol-${avatarWidth}px symbol-rounded rounded-1`}
          >
            <div
              className={`symbol-label bg-white text-primary fs-${textTypology} fw-normal fs-2`}
            >
              +
            </div>
          </div>
          <span
            className={`text-truncate text-nowrap ms-4 fs-4 ${
              hideFullName && 'd-none'
            }`}
          ></span>
        </div>
      )}
      {name && (
        <div
          className={`overflow-hidden mw-${mainWidth}px d-flex align-items-center`}
        >
          <div
            className={`symbol symbol-${avatarWidth}px symbol-rounded rounded-1`}
          >
            <div
              className={`symbol-label bg-${bgColor} text-${avatarTextColor} fs-${avatarTextTypology}`}
              style={{
                backgroundImage: `linear-gradient(225deg, ${gradientBgColor?.[0]} 0%, ${gradientBgColor?.[1]} 100%)`,
              }}
            >
              {getFullNameInitials(name) || ''}
            </div>
          </div>
          <span
            className={`text-truncate text-nowrap ms-4 fs-${textTypology} ${
              hideFullName && 'd-none'
            }`}
          >
            {name}
          </span>
        </div>
      )}
    </div>
  );
};

export default Avatar;
