//import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import _ from 'lodash';
import {CanceledItemState, deliveryTimeDictionary, EstimatedDeliveryTimeTypes, PurchaseItem} from 'app/models';

const mecanizouAddress =
  'Rua Ezequiel Freire, 55, térreo - Santana, São Paulo/SP';
const mecanizouShortAddress = 'Rua Ezequiel Freire, 55';
const mecanizouFullAddress =
  'Rua Ezequiel Freire, 55 - Santana, São Paulo - SP, 02034-000, Brazil';

const deliveryTypesOptions: Array<{ label: string; value: string }> = [
  { label: '', value: '' },
  ...Object.keys(deliveryTimeDictionary).map((deliveryTime) => ({
    label: deliveryTimeDictionary[deliveryTime as EstimatedDeliveryTimeTypes],
    value: deliveryTime,
  })),
];

const vehicleTypeOptions = [
  {
    label: 'Selecione...',
    value: '',
  },
  {
    label: 'Moto-bag',
    value: 'MOTORBIKE_BAG',
  },
  {
    label: 'Moto-baú',
    value: 'MOTORBIKE_BOX',
  },
  {
    label: 'Carro sedan',
    value: 'SEDAN',
  },
  {
    label: 'Utilitário',
    value: 'FIORINO',
  },
  {
    label: 'Caminhão',
    value: 'TRUCK',
  },
];

const getMecanizouCoordinates = async () => {
  //const mecanizouGeocode = await geocodeByAddress(mecanizouShortAddress);
  //const {lat, lng} = await getLatLng(mecanizouGeocode?.[0]);

  return {
    coordinates: {
      lat: '-23.5025998',
      lng: '-46.6235947',
    },
    address: mecanizouFullAddress,
  };
};

const getSinglePointLogisticsProviderMessage = (
  itemsArray: PurchaseItem[],
  providerReceiptNumber?: string
) => {
  const messageHeader = providerReceiptNumber
    ? `Para retirada, apresentar o código do pedido: ${providerReceiptNumber} \n`
    : '';

  const messageLines: string = `\n- Por favor seguir com a retirada APENAS dos itens listados abaixo, em nome da MECANIZOU;\n- Mandar comprovante de foto tanto na Coleta quanto na Entrega;\n- NÃO entregar Nota Fiscal, Boleto ou qualquer Cupom no último endereço (já enviamos por e-mail);\n \n Obrigado. \n`;

  let itemsList: string = '';
  let counter: number = 0;

  itemsArray?.forEach(
    ({order_item, total_quantity, total_quantity_canceled}) => {
      itemsList =
        order_item?.status === 'order_canceled' ||
        order_item?.status === 'item_canceled'
          ? itemsList
          : itemsList +
            `\n ${(counter += 1)}- ${order_item?.description || ''} ${
              order_item?.autopart_factory_code
                ? '- código ' + order_item?.autopart_factory_code
                : ''
            } (${total_quantity - total_quantity_canceled} ${
              total_quantity - total_quantity_canceled === 1
                ? 'unidade'
                : 'unidades'
            })`;
    }
  );

  const message = messageHeader.concat(messageLines, itemsList);

  return message;
};

const getReverseLogisticsProviderMessage = (
  cancelledItemsArray: CanceledItemState[]
) => {
  const providersObject: {[key: string]: CanceledItemState[]} = {};

  cancelledItemsArray?.forEach((item: CanceledItemState) => {
    if (Object.keys(providersObject)?.includes(item?.autopartProviderName)) {
      const providerArray = _.cloneDeep(
        providersObject[item?.autopartProviderName]
      );
      providerArray?.push(item);
      providersObject[item?.autopartProviderName] = providerArray;
    } else {
      const providerArray: CanceledItemState[] = [];
      providerArray?.push(item);
      providersObject[item?.autopartProviderName] = providerArray;
    }
  });

  const messageHeader = cancelledItemsArray
    ?.map((provider: any) => provider?.provider_receipt_reference)
    .every((reference: string) => !reference)
    ? ''
    : `Na hora de cada retirada, apresentar o código do pedido correspondente (abaixo).\n`;

  const messageLines: string =
    '\n- Por favor, fazer a retirada dos itens listados abaixo na MECANIZOU;\n- Mandar comprovante de foto em cada ENTREGA.\n \n Obrigado. \n';

  let providerItemsObject: {[key: string]: string} = {};

  for (const providerName in providersObject) {
    const provider = providersObject[providerName];
    let itemsList: string = '';
    let itemsCounter: number = 0;

    provider.forEach((item: CanceledItemState) => {
      itemsList =
        itemsList +
        `\n ${(itemsCounter += 1)}- ${item?.description} ${
          item?.autopartFactoryCode
            ? '- código: ' + item?.autopartFactoryCode
            : ''
        } ${
          provider?.[0]?.provider_receipt_reference &&
          ' - Nº do pedido: ' + provider?.[0]?.provider_receipt_reference
        }.(${item?.total_quantity} ${
          item?.total_quantity === 1 ? 'unidade' : 'unidades'
        })`;
    });

    providerItemsObject[providerName] = itemsList;
  }

  let stopsList: string = '\n PARADAS PARA ENTREGA: ';

  for (const providerName in providerItemsObject) {
    const provider = providersObject[providerName];
    stopsList =
      stopsList +
      `\n \n ${providerName} – Endereço: ${provider?.[0]?.providerAddress}  Coletar: ${providerItemsObject[providerName]}`;
  }

  const message = messageHeader.concat(messageLines, stopsList);

  return message;
};

export {
  deliveryTypesOptions,
  getMecanizouCoordinates,
  getReverseLogisticsProviderMessage,
  getSinglePointLogisticsProviderMessage,
  mecanizouAddress,
  mecanizouShortAddress,
  mecanizouFullAddress,
  vehicleTypeOptions,
};
