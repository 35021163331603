import {Coupon, NewCoupon} from 'app/models';
import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_COUPON_API_URL;
const COUPONS_API_URL = `${API_URL}/coupons`;

export const getCoupons = async (
  query?: string
): Promise<{result: Coupon[]; resultCount: number}> => {
  const response: AxiosResponse<{result: Coupon[]; resultCount: number}> =
    await axios.get(`${COUPONS_API_URL}?${query}`);

  if (response) {
    return response.data;
  }

  throw new Error('REQUESTS_COULD_NOT_GET_COUPONS');
};

export const showCoupon = async (couponUid: string): Promise<Coupon> => {
  return await axios
    .get(`${COUPONS_API_URL}/${couponUid}?includeRelations=true`)
    .then((d: AxiosResponse<Coupon>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const createNewCoupon = async (body: NewCoupon): Promise<Coupon> => {
  return await axios
    .post(`${COUPONS_API_URL}`, body)
    .then((d: AxiosResponse<Coupon>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const updateCoupon = async (
  couponUid: string,
  body: NewCoupon
): Promise<Coupon> => {
  return await axios
    .put(`${COUPONS_API_URL}/${couponUid}`, body)
    .then((d: AxiosResponse<Coupon>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const toggleCoupon = async (couponUid: string): Promise<Coupon> => {
  return await axios
    .patch(`${COUPONS_API_URL}/${couponUid}/toggle`)
    .then((d: AxiosResponse<Coupon>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const deleteCoupon = async (couponUid: string): Promise<Coupon> => {
  return await axios
    .delete(`${COUPONS_API_URL}/${couponUid}`)
    .then((d: AxiosResponse<Coupon>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const setCouponAsEncouraged = async (
  couponUid: string
): Promise<Coupon> => {
  return await axios
    .patch(`${COUPONS_API_URL}/${couponUid}/incentive`)
    .then((d: AxiosResponse<Coupon>) => d.data)
    .catch((e) => {
      throw e;
    });
};
