/* eslint-disable react/jsx-no-target-blank */
import {FC} from 'react';
import {AsideMenuItem} from './AsideMenuItem';

type Props = {
  isMenuOpen: boolean;
};

export const AsideMenuMain: FC<Props> = ({isMenuOpen}) => {
  return (
    <>
      <AsideMenuItem
        to='/catalog/search'
        title='Pesquisa por Peças'
        bsTitle='Pesquisa'
        fontIcon='bi-search'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/quotes/workspace'
        title='Torre de controle'
        bsTitle='Torre de controle'
        fontIcon='bi-file-earmark-text'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/quotes/list'
        title='Listagem de cotações'
        bsTitle='Cotações'
        fontIcon='bi-card-checklist'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/orders'
        title='Pedidos'
        bsTitle='Pedidos'
        fontIcon='bi-box2'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/purchases/tracking'
        title='Compras'
        bsTitle='Compras'
        fontIcon='bi-cart'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/coupons/list'
        title='Cupons'
        bsTitle='Cupons'
        fontIcon='bi-tags-fill'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/deliveries/tracking-single-express'
        title='Acompanhamento de entregas'
        bsTitle='Acompanhamento de entregas'
        fontIcon='bi-truck'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/cancelled-items/list/stock-items'
        title='Cancelamentos e devoluções'
        bsTitle='Cancelamentos e devoluções'
        fontIcon='bi-x-square-fill'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/incident-deliveries/tracking'
        title='Logística reversa'
        bsTitle='Logística reversa'
        fontIcon='bi-arrow-repeat'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/mechanics/list'
        title='Oficinas'
        bsTitle='Oficinas'
        fontIcon='bi bi-shop-window'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/providers/list'
        title='Fornecedores'
        fontIcon='bi-wrench-adjustable'
        bsTitle=' Fornecedores'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/members/list'
        title='Mecanizados'
        fontIcon='bi-people'
        bsTitle=' Mecanizados'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/finance/invoices'
        title='Financeiro'
        fontIcon='bi-currency-dollar'
        bsTitle=' Financeiro'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />

      <AsideMenuItem
        to='/communication/send-csv-messages'
        title='Comunicação'
        fontIcon='bi-telephone-forward'
        bsTitle=' Comunicação'
        className='py-2'
        isMenuOpen={isMenuOpen}
      />
    </>
  );
};
