import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getSuggestionByTerm = async (term: string) => {
  return axios
    .get(`${API_URL}/v1/autocomplete?search_term=${term}`)
    .then(
      (
        d: AxiosResponse<
          {item_id: number; item_code: string; suggestion: string}[]
        >
      ) => d.data
    );
};
