import React from 'react';
import RequestOTPForm from './RequestOTPForm';
import {useAuthContext} from './context/AuthContext';
import {AuthenticationSteps} from './Interfaces/Auth';
import SendCodeOtpForm from './SendCodeOtpForm';

export default function OTPAuthForm() {
  const {GET_OTP_CODE, SEND_OTP_CODE} = AuthenticationSteps;

  const {authStep} = useAuthContext();
  return (
    <div>
      {authStep === GET_OTP_CODE && <RequestOTPForm />}
      {authStep === SEND_OTP_CODE && <SendCodeOtpForm />}
    </div>
  );
}
