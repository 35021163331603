import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';
import {Filter, FilterOption, QueryState} from 'app/models';

export const useFiltersAndPaginationSettings = ({
  state,
  updateState,
  isLoading,
  fixedFilters,
  asyncFilters = [],
  initialQueryState,
  queryResponse,
}: {
  state?: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
  isLoading?: boolean;
  fixedFilters: Filter;
  asyncFilters?: string[];
  initialQueryState: QueryState;
  queryResponse: any[];
}) => {
  const location = useLocation();
  const [prevQueryString, setPrevQueryString] = useState<string>('');
  const [list, setList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [initialFilterValues, setInitialFilterValues] = useState<{
    [key: string]: string | number | boolean;
  }>({});
  const [initialAsyncFilterValues, setInitialAsyncFilterValues] = useState<{
    [key: string]: FilterOption;
  }>({});

  useEffect(() => {
    updateState({
      ...initialQueryState,
      filter: {
        ...initialQueryState.filter,
      },
      page: initialQueryState.page,
      items_per_page: initialQueryState.items_per_page,
    });
  }, []);

  useEffect(() => {
    if (!isLoading && location) {
      const queryString = location?.search;

      if (queryString !== prevQueryString) {
        let filterValuesObject: {[key: string]: string | number | boolean} = {};
        let asyncFilterValuesObject: {[key: string]: FilterOption} = {};

        if (queryString) {
          filterValuesObject = Object.fromEntries(
            new URLSearchParams(queryString)
          );

          for (let key in filterValuesObject) {
            if (asyncFilters.includes(key)) {
              asyncFilterValuesObject[key] = {
                value: filterValuesObject[key],
                label: '',
              };
            }
          }

          setInitialFilterValues(filterValuesObject);
          !_.isEqual(asyncFilterValuesObject, initialAsyncFilterValues) &&
            setInitialAsyncFilterValues(asyncFilterValuesObject);
        } else {
          setInitialFilterValues({});
          setInitialAsyncFilterValues({});
        }

        updateState({
          ...initialQueryState,
          filter: {
            ...fixedFilters,
            ...filterValuesObject,
          },
          asyncFilter: {
            ...asyncFilterValuesObject,
          },
          page: 1,
        });
        setCurrentPage(1);
      }

      setPrevQueryString(queryString);
    }
  }, [isLoading, location?.search]);

  useEffect(() => {
    if (!isLoading && currentPage && state?.page !== currentPage) {
      updateState({
        page: currentPage,
      });
    }
  }, [isLoading, currentPage]);

  useEffect(() => {
    !_.isEqual(list, queryResponse) && setList(queryResponse);
  }, [queryResponse]);

  return {
    currentPage,
    setCurrentPage,
    initialFilterValues,
    setInitialFilterValues,
    initialAsyncFilterValues,
    setInitialAsyncFilterValues,
    list,
    setList,
  };
};
