import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import {useFormik} from 'formik';
import {verifyUserAccount} from '../core/_requests';
import AuthCode from 'react-auth-code-input';

const initialValues = {
  email: '',
  code: ''
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de e-mail inválido')
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Email é obrigatório'),
});

export function FirstAccess() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [changeCode, setChangeCode] = useState('')
  let navigate = useNavigate(); 
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      setHasErrors(undefined);
      const body = {
        public_key: values.email,
        code: changeCode
      }
      try {
        await verifyUserAccount(body)
        setHasErrors(false);
        setLoading(false);
        setTimeout(() => navigate('/auth/login'), 2000)
      } catch (error) {
        setHasErrors(true);
        setStatus('Dados incorretos!');
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Primeiro acesso</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Insira seu e-mail e o código de confirmação.
          </div>
        </div>

        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Ocorreu um erro, por favor tente novamente.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              Conta verificada com sucesso!
            </div>
          </div>
        )}

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            E-mail
          </label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            Código
          </label>
          <div className='d-flex flex-column justify-content-center'>
            <AuthCode
              onChange={(res) => {
                setChangeCode(res);
              }}
              containerClassName='d-flex flex-row justify-content-center'
              inputClassName='input-code'
            />
          </div>
          {formik.touched.code && formik.errors.code && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.code}</span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            {!loading && <span className='indicator-label'>Enviar</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancelar
            </button>
          </Link>{' '}
        </div>
      </form>
    </>
  );
}
