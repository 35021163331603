import {errors} from './errors';
import {purchaseTracking} from './purchaseTracking';
import {incidentDeliveryTracking} from './incidentDeliveryTracking';

const flattenMessages = (
  nestedMessages: {[key: string]: {} | string},
  prefix = ''
) => {
  return Object.keys(nestedMessages).reduce(
    (messages: {[key: string]: string}, key) => {
      let value = nestedMessages[key];
      let prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return messages;
    },
    {}
  );
};

const messages = {
  errors,
  purchaseTracking,
  incidentDeliveryTracking,
};

export default flattenMessages(messages);
