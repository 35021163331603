
export const  initialNotificationsState ={
    state: {type: 'info', message:''},
    updateState: () => {}
}

export type NotificationsContextProps ={
    state: NotificationsState,
    updateState: (updates: NotificationsState) => void
  }
  
  export type NotificationsState = {
    delay?: number
    autohide?: boolean
    message: string
    type?: string
    width?: number
  }