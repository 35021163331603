import {FC} from 'react';
import {useLayout} from '../../../core';
import {usePageData} from '../../../core';

const DefaultActions: FC = () => {
  const {pageActions} = usePageData();
  const {config} = useLayout();
  return (
    <div
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
      className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-5 pb-2 pb-lg-0'
    >
      {config.header.pageActions && pageActions && <div>{pageActions}</div>}
    </div>
  );
};

export {DefaultActions};
