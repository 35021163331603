import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {AsideDefault} from './components/aside/AsideDefault';
import {HeaderWrapper} from './components/header/HeaderWrapper';
import {Toolbar} from './components/toolbar/Toolbar';
import {ScrollTop} from './components/ScrollTop';
import {Content} from './components/Content';
import {PageDataProvider} from './core';
import {useLocation} from 'react-router-dom';
import {
  DrawerMessenger,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials';
import {MenuComponent} from '../assets/ts/components';

const MasterLayout = (props? : {operation?: boolean}) => {
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev: boolean) => !prev);
  };

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        {/* begin::Page */}
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
          {/* begin::Wrapper */}
          <div
            className={`${
              isMenuOpen ? 'open-menu-wrapper' : 'wrapper'
            } d-flex flex-column flex-row-fluid`}
            id='kt_wrapper'
          >
            <HeaderWrapper isMenuOpen={isMenuOpen} />

            <Toolbar />

            {/* begin::Content */}
            <div
              id='kt_content'
              className={`${
                !props?.operation && 'content'
              } d-flex flex-column flex-column-fluid`}
            >
              <Content>
                <Outlet />
              </Content>
            </div>
            {/* end::Content */}
            {/* <Footer /> */}
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  );
};

export {MasterLayout};
