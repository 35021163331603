/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from '../../../../app/modules/auth';

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth();
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column mx-3'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.user?.account_user[0]?.account?.name || ''}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.user.email}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item  my-1'>
        <Link to='/accounts/settings' className='menu-link '>
          <i className='bi bi-gear-fill fs-3 mx-3'></i>
          <span>Configurações</span>
        </Link>
      </div>
      <div className='menu-item '>
        <a onClick={logout} className='menu-link  d-flex flex-row'>
          <i className='bi bi-box-arrow-right fs-3 mx-3'></i>
          <span className=''>Sair</span>
        </a>
      </div>
    </div>
  );
};

export {HeaderUserMenu};
