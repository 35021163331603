/* eslint-disable react-hooks/exhaustive-deps */
import {FC} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {KTSVG, toAbsoluteUrl} from '../../../helpers';
import {useLayout} from '../../core';
import {DefaultTitle} from './page-title/DefaultTitle';
import {DefaultActions} from './actions/DefaultActions';
import {DefaultHeader} from './header-navigation/DefaultHeader';
import {Topbar} from './Topbar';

type Props = {
  isMenuOpen: boolean;
};

export const HeaderWrapper:FC<Props> = ({isMenuOpen}) => {
  const {config, classes, attributes} = useLayout();
  const {header, pageTitle} = config;

  return (
    <div
      id='kt_header'
      className={clsx(
        `header ${isMenuOpen ? 'open-menu-header-fixed' : ''}`,
        classes.header.join(' '),
        'bg-white align-items-stretch '
      )}
      {...attributes.headerMenu}
    >
      {/* begin::Container */}
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}

        <div
          className='d-flex align-items-center d-lg-none ms-n3 me-1'
          title='Show aside menu'
        >
          <div
            className='btn btn-icon btn-active-color-primary w-40px h-40px'
            id='kt_aside_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/abstract/abs015.svg'
              className='svg-icon-1'
            />
          </div>
        </div>
        {/* end::Aside mobile toggle */}

        {/* begin::Mobile logo */}
        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
          <Link to='/quotes/workspace' className='d-lg-none'>
            <img
              src={toAbsoluteUrl('/media/logos/logo-demo4.svg')}
              alt='logo'
              className='h-30px'
            />
          </Link>
        </div>
        {/* end::Mobile logo */}

        {pageTitle?.display && (
          <div className='d-flex align-items-center' id='kt_header_wrapper'>
            <DefaultTitle />
          </div>
        )}

        {/* begin::Wrapper */}
        <div
          className={clsx(
            'd-flex align-items-stretch',
            `justify-content-${
              header.menu ? 'between' : 'end'
            }`,
            'flex-lg-grow-1'
          )}
        >
          {header.menu && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <DefaultHeader />
            </div>
          )}
          <div className='d-flex align-items-stretch'>
            {header.pageActions && <DefaultActions />}
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Container */}
    </div>
  );
}
