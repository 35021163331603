import { FileType, ValidFileType } from "../components/UploadButton/types"

export const isValidFileSize = (file: File, maxSizeMb: number) => {
  if (file.size > maxSizeMb * 1024 * 1024) return false
  return true
}

const fileTypeHash: ValidFileType = {
  'application/pdf': 'pdf',
  'image/png': 'png',
  'text/csv': 'csv',
  'text/xml': 'xml',
  'image/jpeg': 'jpeg',
}

export const isValidFileType = (file: File, fileTypes: FileType[]) => {
  if (
    Object.keys(fileTypeHash).includes(file.type) &&
    fileTypes.includes(fileTypeHash[file.type])
  ) {
    return true
  }
  return false
}
