import {Offer, OfferSearchResponseItem, Quote, SearchVehicle} from 'app/models';
import {QuoteRequestItem} from 'app/models/QuoteRequestItem';
import {getOffersRequest, listOffersRequest} from 'app/requests';

export const getOffers = async (
  quoteItem: string,
  quoteRequestItem: QuoteRequestItem | {id: number; uid: string},
  quote: Quote,
  searchVehicle?: SearchVehicle
): Promise<OfferSearchResponseItem> => {
  try {
    const body: {
      quoteItem: string;
      quote: any;
      quoteRequestItem: QuoteRequestItem | {id: number; uid: string};
      client_account_uid: string;
      searchVehicle?: SearchVehicle;
      origin: string;
      autoparts?: string;
    } = {
      quoteItem,
      quote,
      quoteRequestItem: quoteRequestItem,
      client_account_uid:
        quote?.client_account_uid || quote?.quote_requests?.client_account_uid,
      origin: 'quote_operations',
      searchVehicle: searchVehicle || undefined,
    };
    const searchOffersResultRaw = await getOffersRequest(body);

    return {
      offers: searchOffersResultRaw.offers,
      quoteRequestItemId: quoteRequestItem.id,
    };
  } catch (error) {
    throw error;
  }
};

export const listOffers = async (params: {
  offer_factory_code?: string;
  offer_brand_name?: string;
  autopart_id?: number;
  client_account_uid?: string;
  quantity_per_package?: number;
  origin: string;
}): Promise<Offer[]> => {
  try {
    const listOffersResult = await listOffersRequest(params);

    return listOffersResult;
  } catch (error) {
    throw error;
  }
};
