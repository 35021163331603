/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

export interface PageLink {
  id?: string;
  title: string;
  fontIcon?: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
}

export interface PageDataContextModel {
  pageTitle?: string;
  setPageTitle: (_title: string) => void;
  pageActions?: ReactNode;
  setPageActions: (_actions: ReactNode) => void;
  pageDescription?: string;
  setPageDescription: (_description: string) => void;
  pageBreadcrumbs?: Array<PageLink>;
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
  pageHeaderNavigation?: Array<PageLink>;
  setPageHeaderNavigation: (_navigation: PageLink[]) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageActions: (_actions: ReactNode) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageHeaderNavigation: (_navigation: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
});

const PageDataProvider: React.FC = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageActions, setPageActions] = useState<ReactNode>('');
  const [pageDescription, setPageDescription] = useState<string>('');
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
  const [pageHeaderNavigation, setPageHeaderNavigation] = useState<
    Array<PageLink>
  >([]);

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageActions,
    setPageActions,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageHeaderNavigation,
    setPageHeaderNavigation,
  };
  return (
    <PageDataContext.Provider value={value}>
      {children}
    </PageDataContext.Provider>
  );
};

function usePageData() {
  return useContext(PageDataContext);
}

type Props = {
  description?: string;
  breadcrumbs?: Array<PageLink>;
};

const PageTitle: FC<Props> = ({children, description, breadcrumbs}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData();
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString());
    }
    return () => {
      setPageTitle('');
    };
  }, [children]);

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }
    return () => {
      setPageDescription('');
    };
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => {
      setPageBreadcrumbs([]);
    };
  }, [breadcrumbs]);

  return <></>;
};

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData();
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }
    return () => {
      setPageDescription('');
    };
  }, [children]);
  return <></>;
};

const PageActions: React.FC = ({children}) => {
  const {setPageActions} = usePageData();
  useEffect(() => {
    if (children) {
      setPageActions(children);
    }
    return () => {
      setPageActions([]);
    };
  }, [children]);
  return <></>;
};

type NavigationProps = {
  headerNavigation?: Array<PageLink>;
};

const HeaderNavigation: React.FC<NavigationProps> = ({headerNavigation}) => {
  const {setPageHeaderNavigation} = usePageData();
  useEffect(() => {
    if (headerNavigation) {
      setPageHeaderNavigation(headerNavigation);
    }
    return () => {
      setPageHeaderNavigation([]);
    };
  }, [headerNavigation]);
  return <></>;
};

export {
  PageDescription,
  PageTitle,
  PageActions,
  PageDataProvider,
  HeaderNavigation as HeaderNavigations,
  usePageData,
};
