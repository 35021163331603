import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL
const PAYMENT_CONDITION_URL = `${API_URL}/payments/payment-conditions`

export const getPaymentConditionOptions = async (): Promise<any> => {
    return await axios
      .get(`${PAYMENT_CONDITION_URL}`)
      .then((d: AxiosResponse<any>) => d.data)
      .catch(e => {
        return [];
      }).finally(() => {
        return []
      })
};