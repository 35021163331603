import axios, {AxiosResponse} from 'axios';
import {
  Delivery,
  GetIntegratedLogisticsProvidersResponseData,
} from 'app/models';

const API_URL = process.env.REACT_APP_API_URL;
const LOGISTICS_REQUESTS_URL = `${API_URL}/logistics-requests`;

export const cancelAssigningDriverDelivery = async (
  deliveryUid: string,
  body: {mustAttemptToCancelLogistics: boolean}
): Promise<Delivery> => {
  try {
    const response: AxiosResponse<Delivery> = await axios.put(
      `${LOGISTICS_REQUESTS_URL}/${deliveryUid}/cancel-unassigned-delivery`,
      body
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const toggleIntegratedLogisticsProviders = async (body?: {
  [key: string]: boolean;
}): Promise<GetIntegratedLogisticsProvidersResponseData> => {
  const response: AxiosResponse<GetIntegratedLogisticsProvidersResponseData> =
    await axios.put(
      `${LOGISTICS_REQUESTS_URL}/integrated-logistics-providers`,
      body
    );

  if (response) {
    return response.data;
  }

  throw new Error(
    'LOGISTICS_REQUEST_SVC_COULD_NOT_UPDATE_LOGISTICS_INTEGRATED_PROVIDERS'
  );
};
