import axios, { AxiosResponse } from 'axios';

import { GetFeatureFlagsResponse, GetSingleFeatureFlagResponse } from 'app/models';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllFeatureFlags = async (
  clientAccountUid?: string,
  email?: string
): Promise<GetFeatureFlagsResponse> => {
  const response: AxiosResponse<GetFeatureFlagsResponse> = await axios.get(
    `${API_URL}/feature-flags`,
    { params: { clientAccountUid, email } }
  );

  if (response) {
    return response.data;
  }

  throw new Error('CONFIG_SVC_GET-FEATURE-FLAGS');
};

export const getFeatureFlag = async (
  flagName: string,
  clientAccountUid?: string,
  email?: string
): Promise<GetSingleFeatureFlagResponse> => {
  const response: AxiosResponse<GetSingleFeatureFlagResponse> = await axios.get(
    `${API_URL}/feature-flag/${flagName}`,
    { params: { clientAccountUid, email } }
  );

  if (response) {
    return response.data;
  }

  throw new Error('CONFIG_SVC_GET-FEATURE-FLAG');
};

export const getFeatureFlagV2 = async (
  flagName: string,
  clientAccountUid?: string,

): Promise<GetSingleFeatureFlagResponse> => {
  const response: AxiosResponse<GetSingleFeatureFlagResponse> = await axios.post(
    `${API_URL}/v2/feature-flag/${flagName}`,

    {
      id: "Ops Panel",
      kind: "user",
      params: {
        clientAccountUid
      }
    }

  );

  if (response) {
    return response.data;
  }

  throw new Error('CONFIG_SVC_GET-FEATURE-FLAG');
};

