import {AuthProvider} from './context/AuthContext';
import OTPAuth from './OTPAuth';

export default function LoginOTPAuthLayout() {
  return (
    <AuthProvider>
      <OTPAuth />
    </AuthProvider>
  );
}
