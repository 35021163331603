import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {useFormik} from 'formik';
import {confirmNewPassword} from '../core/_requests';
import YupPassword from 'yup-password';
import AuthCode from 'react-auth-code-input';
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components';
import {
  NotificationsProvider,
  useNotifications,
} from 'app/shared/providers/NotificationsProvider';

YupPassword(Yup);

const initialValues = {
  public_key: '',
  private_key: '',
  confirm_password: '',
  code: '',
};

const forgotPasswordSchema = Yup.object().shape({
  public_key: Yup.string()
    .email('Precisa ser em formato de email')
    .min(3, 'Min')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  private_key: Yup.string()
    .min(8, 'Senha precisa ter no minimo 8 caracteres')
    .minLowercase(1, 'Precisa ter ao menos uma letra minuscula')
    .minUppercase(1, 'Precisa ter ao menos uma letra maiuscula')
    .minNumbers(1, 'Precisa ter ao menos um numero')
    .required('Precisa informar uma nova senha'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('private_key'), null],
    'Senha informada é diferente'
  ),
  code: Yup.string().required(),
});

export function ConfirmNewPasswordForm() {
  const {updateState: notificationsUpdateState} = useNotifications();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [changePasswordData, setChangePasswordData] = useState({
    code: '',
    public_key: '',
    private_key: '',
    step: 'send-code',
  });

  const location = useLocation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      setHasErrors(undefined);
      confirmNewPassword(values.code, values.public_key, values.private_key)
        .then(({data: {result}}) => {
          setHasErrors(false);
          setLoading(false);
          notificationsUpdateState({
            message: 'Senha alterada com sucesso',
            autohide: true,
            delay: 3000,
            type: 'success',
          });
        })
        .catch(() => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus('Por favor revise os dados enviados');
        });
      setTimeout(() => {
        navigate('/auth');
      }, 3000);
    },
  });

  const handleWithCode = (code: string) => {
    formik.setValues({...formik.values, code});
    setChangePasswordData((prev) => ({...prev, code, step: 'change-password'}));
  };

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    const queryString = location?.search;
    const {email} = Object.fromEntries(new URLSearchParams(queryString));
    if (email && queryString) {
      formik.setValues({...formik.values, public_key: email});
    }
  }, []);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, [changePasswordData.step]);

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Mude sua senha!</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {changePasswordData.step === 'send-code'
              ? 'Entre com o código para resetar a senha'
              : 'Entre com a nova senha'}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Desculpe, parece que tivemos algum erro, por favor tente
              novamente.
            </div>
          </div>
        )}

        {/* end::Title */}

        {changePasswordData.step === 'send-code' ? (
          <>
            <div className='d-flex flex-column justify-content-center'>
              <AuthCode
                onChange={(res) => {
                  setChangePasswordData((prev) => ({...prev, code: res}));
                }}
                containerClassName='d-flex flex-row justify-content-center'
                inputClassName='input-code'
              />
              <div className='d-flex flex-row mt-5 justify-content-center'>
                <button
                  onClick={() => handleWithCode(changePasswordData.code)}
                  className='btn btn-primary'
                >
                  Informar código
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='d-flex flex-row'>
              <div
                className='d-flex flex-column mx-9'
                data-kt-password-meter='true'
              >
                <label className='form-label fw-bolder text-dark fs-6'>
                  Nova senha
                </label>
                <input
                  type={'password'}
                  placeholder='Ex. NovaSenha1234'
                  autoComplete='off'
                  {...formik.getFieldProps('private_key')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid':
                        formik.touched.private_key && formik.errors.private_key,
                    },
                    {
                      'is-valid':
                        formik.touched.private_key &&
                        !formik.errors.private_key,
                    }
                  )}
                />
                {formik.touched.private_key && formik.errors.private_key && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.private_key}</span>
                    </div>
                  </div>
                )}
                <div
                  className='d-flex align-items-center mt-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-row mt-6'>
              <div className='d-flex flex-column mx-9'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Confirmar nova senha
                </label>
                <input
                  type={'password'}
                  placeholder='EX. NovaSenha1234'
                  autoComplete='off'
                  {...formik.getFieldProps('confirm_password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid':
                        formik.touched.confirm_password &&
                        formik.errors.confirm_password,
                    },
                    {
                      'is-valid':
                        formik.touched.confirm_password &&
                        !formik.errors.confirm_password,
                    }
                  )}
                />
                {formik.touched.confirm_password &&
                  formik.errors.confirm_password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>
                          {formik.errors.confirm_password}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className='d-flex flex-row mt-8 justify-content-around'>
              <button type='submit' className='btn btn-primary me-4'>
                Atualizar senha
              </button>
              <Link to={`/auth`}>
                <button
                  type='reset'
                  className='btn btn-outline btn-outline-primary'
                >
                  Cancelar
                </button>
              </Link>
            </div>
          </>
        )}
        {/* end::Form group */}
      </form>
    </>
  );
}

export function ConfirmNewPassword() {
  return (
    <>
      <NotificationsProvider>
        <ConfirmNewPasswordForm />
      </NotificationsProvider>
    </>
  );
}
