import axios from "axios";

const ViaCep = axios.create({
    baseURL: 'https://viacep.com.br/ws',
  });
  ViaCep.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      throw error.response.data.error || error;
    }
  );

  export function searchAddressByZipCode(zipcode: any) {
    return ViaCep.request({
      url: `/${zipcode}/json/`,
      method: "GET"
    }).then(response => {
      return response;
    }).catch(error => {
      return error;
    });
  };

  const UFIBGE = axios.create({
    baseURL: 'https://servicodados.ibge.gov.br/api/v1',
  });

  UFIBGE.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      throw error?.response?.data?.error || error;
    }
  );

  export function getAllUfs(query?: string) {
    return UFIBGE.request({
      url: `/localidades/estados?${query}`,
      method: 'GET'
    }).then(response => {
      return response
    }).catch( error => {
      return error;
    });
  }

  