import axios, {AxiosResponse} from 'axios';
import fileDownload from 'js-file-download';

export const downloadFile = (url: string, filename: string): Promise<any> => {
  return axios
    .get(url, {
      headers: {
        Authorization: 'ANONYMOUS',
      },
      responseType: 'blob',
    })
    .then((d: AxiosResponse<any>) => {
      fileDownload(d.data, filename);
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};
