import {FC} from 'react';
import {useLang} from './Metronici18n';
import {IntlProvider} from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/pt';

import ptMessages from './messages/pt-BR';

const allMessages = {
  'pt-BR': ptMessages,
};

const I18nProvider: FC = ({children}) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export {I18nProvider};
