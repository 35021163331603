import { AccountUser } from './AccountUser';
import { User } from './User';

export type Account = {
  id?: number
  uid: string
  pricing_tier_uid?: string
  name: string
  email?:string
  is_active: boolean
  account_user: Array<AccountUser>
  createdAt: string
  updatedAt: string
  kind: string
  user?: User
}

export type GetAccountsRequestData = {
  name?: string;
  kind?: string;
}

export type AccountsQueryResponse = Array<Account>

export const initialAccount: Account = {
  uid: '',
  name: '',
  is_active: false,
  account_user:[],
  createdAt: '',
  updatedAt: '',
  kind: '',
}


export interface CreateAccountResponse {
  message: string
  createdUser: CreatedUser
}

export interface CreatedUser {
  professionalAccount: ProfessionalAccount
  accounts: Account[]
  credentials: Credentials
}

export interface InvitedUser {
  message: string
  createdPersonalAccount: any
  mainAccountUserRelation: AccountUserRelation
}

export interface ProfessionalAccount {
  id: number
  uid: string
  pricing_tier_uid: any
  name: string
  is_active: boolean
  kind: string
  createdAt: string
  updatedAt: string
}

export interface Account_user {
  id: number
  uid: string
  account_id: number
  user_id: number
  role: string
  is_active: boolean
  createdAt: string
  updatedAt: string
  account: Account
}

export interface Credentials {
  username: string
  sub: string
}

export interface AccountUserRelation {
  name: string
  role: string
  kind: string
}
export type CustomerAccount = {
  user_accounts_id: number;
  user_external_uid: string;
  personal_account: ProfessionalAccount
}
