import axios, {AxiosResponse} from 'axios';
import {IntegratedLogisticsProvider} from 'app/models';

const API_URL = process.env.REACT_APP_API_URL;
const LOGISTICS_URL = `${API_URL}/logistics`;

export const getIntegratedLogisticsProviders = async (params?: {
  [key: string]: unknown;
}): Promise<IntegratedLogisticsProvider[]> => {
  const response: AxiosResponse<IntegratedLogisticsProvider[]> =
    await axios.get(`${LOGISTICS_URL}/integrated-logistics-providers`, {
      params,
    });

  if (response) {
    return response.data;
  }

  throw new Error('LOGISTICS_SVC_COULD_NOT_GET_LOGISTICS_INTEGRATED_PROVIDERS');
};
