import { AccountUser, BodyUpdateRoleAccountUser } from 'app/models'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_ACCOUNTS_URL = `${API_URL}/account-users`

export const updateAccountUsersRole = (uid: string, body: BodyUpdateRoleAccountUser): Promise<AccountUser> => {
  return axios
    .put(`${GET_ACCOUNTS_URL}/${uid}`, body)
    .then((d: AxiosResponse<AccountUser>) => d.data)
}