import { RenegotiationResponse, Statement } from "app/models"
import axios, { AxiosResponse } from "axios"

const API_URL = process.env.REACT_APP_API_URL
const STATEMENT_API_URL = `${API_URL}/statements`

export const getStatements = (params?: string): Promise<{result: Statement[], resultCount: number}> => {
    return axios
      .get(`${STATEMENT_API_URL}?${params}`)
      .then((d: AxiosResponse<{result: Statement[], resultCount: number}>) => {
        return d.data;
      })
      .catch(e => {
        throw e
      }).finally(() => {
        return []
      })
};

export const getStatementByUid = (uid: string): Promise<Statement> => {
  return axios
    .get(`${STATEMENT_API_URL}/${uid}`)
    .then((d: AxiosResponse<Statement>) => d.data)
    .catch(e => {
      throw e
    })
};

export const renegotiateStatement = (body: {statementUids: string[], paymentConditionUid: string, utilisedBalance:number}): 
Promise<RenegotiationResponse> => {
  return axios
    .post(`${STATEMENT_API_URL}/renegotiation`, body)
    .then((d: AxiosResponse<RenegotiationResponse>) => d.data)
    .catch(e => {
      throw e
    })
};
