import axios, {AxiosResponse} from 'axios';
import {MecanizouAccountInformation} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_ACCOUNTS_URL = `${API_URL}/accounts/mecanizou`;

export const getMecanizouMembers = async (query?: string) => {
  const response: AxiosResponse<MecanizouAccountInformation> = await axios.get(
    `${GET_ACCOUNTS_URL}${query && `?${query}`}`
  );

  if (response?.data) {
    return response.data.account_user;
  } else {
    throw new Error('REQUEST_COULD_NOT_GET_MECANIZOU_MEMBERS');
  }
};
