import React, {useState} from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {initiateOTP, login} from '../../core/_requests';
import {useAuthContext} from './context/AuthContext';
import {AuthenticationSteps} from './Interfaces/Auth';

export default function OTPAuth() {
  const [loading, setLoading] = useState(false);
  const {updateAuthContext} = useAuthContext();
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Formato inválido de e-mail')
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('E-mail é obrigatório'),
  });

  const initialValues = {
    email: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const {session} = await initiateOTP(values.email);
        updateAuthContext({
          session,
          public_key: values.email,
          authStep: AuthenticationSteps.SEND_OTP_CODE,
        });
        setLoading(true);
      } catch (error) {
        console.log('🚀 ~ onSubmit: ~ error:', error);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className='d-flex flex-column w-100'>
      <div>
        <label>Email</label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid w-100',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
      </div>
      <div className='mt-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Enviar código</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
