import { datadogRum } from '@datadog/browser-rum';

export const datadog = {
  init: (clientToken: string, applicationId: string) => {
    if (process.env.REACT_APP_NODE_ENVIRONMENT === 'development') return;
    if (!applicationId && !clientToken) return;

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service: 'ops-panel',
      env: process.env.REACT_APP_NODE_ENVIRONMENT,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 50,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
  },

  identify: (uid: string) => {
    if (process.env.REACT_APP_NODE_ENVIRONMENT === 'development') return;
    datadogRum.setUser({
      id: uid,
    });
  },
};