export const purchaseTracking = {
  title: {
    priority: {
      express: 'Compras Pronta Entrega',
      scheduled: 'Compras Agendadas',
    },
  },
  icon: {
    priority: {
      express: 'bi bi-cart3',
      scheduled: 'bi bi-calendar-range',
    },
  },
};
