import {FC, useState, createContext, useContext, useEffect} from 'react';
import {Toast} from 'react-bootstrap';

import {
  initialNotificationsState,
  NotificationsState,
  NotificationsContextProps,
} from './notificationsStates';

const NotificationsContext = createContext<NotificationsContextProps>(
  initialNotificationsState
);

const NotificationsProvider: FC = ({children}) => {
  const [state, setState] = useState<NotificationsState>(
    initialNotificationsState.state
  );
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);

  const updateState = (updates: NotificationsState) => {
    setState(updates);
  };

  useEffect(() => {
    state.message && setIsToastVisible(true);
  }, [state]);

  type ColorsModel = {
    [key: string]: string;
  };

  type ColorsSchemaModel = {
    [key: string]: ColorsModel;
  };

  const colorsSchema: ColorsSchemaModel = {
    info: {textColor: 'primary', backgroundColor: 'navy-80'},
    warning: {textColor: 'white', backgroundColor: 'empty-quarter'},
    error: {textColor: 'white', backgroundColor: 'dark-vulcan'},
    success: {textColor: 'army-20', backgroundColor: 'army-80'},
  };

  return (
    <NotificationsContext.Provider value={{state, updateState}}>
      {children}
      <Toast
        className={`d-flex flex-column justify-content-center
        bg-${colorsSchema[state?.type || 'info'].backgroundColor} text-${
          colorsSchema[state?.type || 'info'].textColor
        }
        border border-0 `}
        style={{
          position: 'fixed',
          left: `calc(50% - ${state?.width}px/2)`,
          bottom: '5%',
          zIndex: 1000,
          width: state?.width || 400,
        }}
        onClose={() => setIsToastVisible(false)}
        show={isToastVisible}
        delay={state.delay}
        autohide={state.autohide}
      >
        <Toast.Body className='d-flex justify-content-between fs-5 fw-bold py-3 ps-5'>
          <span className='align-self-end'>{state?.message}</span>
          <i
            role='button'
            onClick={() => setIsToastVisible(false)}
            className={`bi bi-x-lg align-self-start fs-8 text-${
              colorsSchema[state?.type || 'info'].textColor
            }`}
          />
        </Toast.Body>
      </Toast>
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationsContext);

export {NotificationsProvider, useNotifications};
