export interface MessageModel {
  user: number;
  type: 'in' | 'out';
  text: string;
  time: string;
  template?: boolean;
}

const defaultMessages: Array<MessageModel> = [
  {
    user: 4,
    type: 'in',
    text: 'How likely are you to recommend our company to your friends and family ?',
    time: '2 mins',
  },
  {
    user: 2,
    type: 'out',
    text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
    time: '5 mins',
  },
  {
    user: 4,
    type: 'in',
    text: 'Ok, Understood!',
    time: '1 Hour',
  },
  {
    user: 2,
    type: 'out',
    text: 'You’ll receive notifications for all issues, pull requests!',
    time: '2 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
    time: '3 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: 'Most purchased Business courses during this sale!',
    time: '4 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
    time: '5 Hours',
  },
  {
    template: true,
    user: 2,
    type: 'out',
    text: '',
    time: 'Just now',
  },
  {
    template: true,
    user: 4,
    type: 'in',
    text: 'Right before vacation season we have the next Big Deal for you.',
    time: 'Just now',
  },
];

export interface UserInfoModel {
  initials?: {
    label: string;
    state: 'warning' | 'danger' | 'primary' | 'success' | 'info';
  };
  name: string;
  avatar?: string;
  email: string;
  position: string;
  online: boolean;
}

const defaultUserInfos: Array<UserInfoModel> = [
  {
    name: 'Emma Smith',
    avatar: 'avatars/300-6.jpg',
    email: 'e.smith@kpmg.com.au',
    position: 'Art Director',
    online: false,
  },
  {
    name: 'Melody Macy',
    initials: {label: 'M', state: 'danger'},
    email: 'melody@altbox.com',
    position: 'Marketing Analytic',
    online: true,
  },
  {
    name: 'Max Smith',
    avatar: 'avatars/300-1.jpg',
    email: 'max@kt.com',
    position: 'Software Enginer',
    online: false,
  },
  {
    name: 'Sean Bean',
    avatar: 'avatars/300-5.jpg',
    email: 'sean@dellito.com',
    position: 'Web Developer',
    online: false,
  },
  {
    name: 'Brian Cox',
    avatar: 'avatars/300-25.jpg',
    email: 'brian@exchange.com',
    position: 'UI/UX Designer',
    online: false,
  },
  {
    name: 'Mikaela Collins',
    initials: {label: 'M', state: 'warning'},
    email: 'mikaela@pexcom.com',
    position: 'Head Of Marketing',
    online: true,
  },
  {
    name: 'Francis Mitcham',
    avatar: 'avatars/300-9.jpg',
    email: 'f.mitcham@kpmg.com.au',
    position: 'Software Arcitect',
    online: false,
  },

  {
    name: 'Olivia Wild',
    initials: {label: 'O', state: 'danger'},
    email: 'olivia@corpmail.com',
    position: 'System Admin',
    online: true,
  },
  {
    name: 'Neil Owen',
    initials: {label: 'N', state: 'primary'},
    email: 'owen.neil@gmail.com',
    position: 'Account Manager',
    online: true,
  },
  {
    name: 'Dan Wilson',
    avatar: 'avatars/300-23.jpg',
    email: 'dam@consilting.com',
    position: 'Web Desinger',
    online: false,
  },
  {
    name: 'Emma Bold',
    initials: {label: 'E', state: 'danger'},
    email: 'emma@intenso.com',
    position: 'Corporate Finance',
    online: true,
  },
  {
    name: 'Ana Crown',
    avatar: 'avatars/300-12.jpg',
    email: 'ana.cf@limtel.com',
    position: 'Customer Relationship',
    online: false,
  },
  {
    name: 'Robert Doe',
    initials: {label: 'A', state: 'info'},
    email: 'robert@benko.com',
    position: 'Marketing Executive',
    online: true,
  },
  {
    name: 'John Miller',
    avatar: 'avatars/300-13.jpg',
    email: 'miller@mapple.com',
    position: 'Project Manager',
    online: false,
  },
  {
    name: 'Lucy Kunic',
    initials: {label: 'L', state: 'success'},
    email: 'lucy.m@fentech.com',
    position: 'SEO Master',
    online: true,
  },
  {
    name: 'Ethan Wilder',
    avatar: 'avatars/300-21.jpg',
    email: 'ethan@loop.com.au',
    position: 'Accountant',
    online: true,
  },
];

const messageFromClient: MessageModel = {
  user: 4,
  type: 'in',
  text: 'Thank you for your awesome support!',
  time: 'Just now',
};

export interface AlertModel {
  title: string;
  description: string;
  time: string;
  icon: string;
  state: 'primary' | 'danger' | 'warning' | 'success' | 'info';
}

const defaultAlerts: Array<AlertModel> = [
  {
    title: 'Project Alice',
    description: 'Phase 1 development',
    time: '1 hr',
    icon: 'icons/duotune/technology/teh008.svg',
    state: 'primary',
  },
  {
    title: 'HR Confidential',
    description: 'Confidential staff documents',
    time: '2 hrs',
    icon: 'icons/duotune/general/gen044.svg',
    state: 'danger',
  },
  {
    title: 'Company HR',
    description: 'Corporeate staff profiles',
    time: '5 hrs',
    icon: 'icons/duotune/finance/fin006.svg',
    state: 'warning',
  },
  {
    title: 'Project Red',
    description: 'New frontend admin theme',
    time: '2 days',
    icon: 'icons/duotune/files/fil023.svg',
    state: 'success',
  },
  {
    title: 'Project Breafing',
    description: 'Product launch status update',
    time: '21 Jan',
    icon: 'icons/duotune/maps/map001.svg',
    state: 'primary',
  },
  {
    title: 'Banner Assets',
    description: 'Collection of banner images',
    time: '21 Jan',
    icon: 'icons/duotune/general/gen006.svg',
    state: 'info',
  },
  {
    title: 'Icon Assets',
    description: 'Collection of SVG icons',
    time: '20 March',
    icon: 'icons/duotune/art/art002.svg',
    state: 'warning',
  },
];
export interface LogModel {
  code: string;
  state: 'success' | 'danger' | 'warning';
  message: string;
  time: string;
}

const defaultLogs: Array<LogModel> = [
  {code: '200 OK', state: 'success', message: 'New order', time: 'Just now'},
  {code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs'},
  {code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs'},
  {code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days'},
  {code: '200 OK', state: 'success', message: 'API connection', time: '1 week'},
  {
    code: '200 OK',
    state: 'success',
    message: 'Database restore',
    time: 'Mar 5',
  },
  {code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15'},
  {
    code: '300 WRN',
    state: 'warning',
    message: 'Server OS update',
    time: 'Apr 3',
  },
  {code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30'},
  {code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10'},
  {
    code: '500 ERR',
    state: 'danger',
    message: 'Withdrawal process',
    time: 'Sep 10',
  },
  {code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10'},
];

const quoteRequest: any = {
  id: 1,
  external_uid: '48163fc6-ea98-41ac-b7bc-289a01026eb5',
  first_message_at: '2022-04-13T23:41:00.000Z',
  closed_at: null,
  client_account_uid: '47e837f2-ce64-42e0-8e65-1c58e8aa3a9d',
  vehicle_uid: '5ecbfa40-40b8-49c0-9d25-0f8212cb4fae',
  vehicle_search_term: 'fhd8741',
  status: 'IN_PROGRESS',
  creator_account_user_uid: '3d434cab-3212-474d-9c0b-dfcbf1e2c241',
  created_at: '2022-04-13T23:53:03.868Z',
  updated_at: '2022-04-13T23:53:26.134Z',
  is_active: true,
  client_account_user_uid: '153b398f-c906-4461-9503-85109e5aaec1',
  quote_request_items: [
    {
      id: 1,
      external_uid: '01d6a74f-4c6e-4c17-8e6f-760c34038e21',
      description: 'filtro de combustivel',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
    },
    {
      id: 2,
      external_uid: 'f6c91358-4d9f-4e5f-b6fa-e70796f51b66',
      description: 'amortecedor',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
    },
  ],
  quote_request_medias: [
    {
      id: 1,
      external_uid: '46280ab5-b5d7-4689-8ca1-9b418e13d3cf',
      url: 'https://prod-quote-service-quote-quoterequestmediasbucket-3qvvyexd1b23.s3.sa-east-1.amazonaws.com/fecd8b3b-d676-4153-ba00-5e3b1425c81a.jpeg',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
      media_type: 'IMAGE',
      media_name: 'fecd8b3b-d676-4153-ba00-5e3b1425c81a.jpeg',
    },
    {
      id: 2,
      external_uid: '5c6d16fc-d29c-4e4b-82a4-a494dce3ce5a',
      url: 'https://prod-quote-service-quote-quoterequestmediasbucket-3qvvyexd1b23.s3.sa-east-1.amazonaws.com/d7e51581-7d8b-4638-bc76-d7002f1dbf4a.ogg',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
      media_type: 'AUDIO',
      media_name: 'd7e51581-7d8b-4638-bc76-d7002f1dbf4a.ogg',
    },
    {
      id: 3,
      external_uid: '03c89eff-9739-453d-962b-2a5264dc3243',
      url: 'https://prod-quote-service-quote-quoterequestmediasbucket-3qvvyexd1b23.s3.sa-east-1.amazonaws.com/9dbe1664-c074-4e67-b88e-febe92aecbd9.ogg',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
      media_type: 'AUDIO',
      media_name: '9dbe1664-c074-4e67-b88e-febe92aecbd9.ogg',
    },
  ],
  quote_request_images: [
    {
      id: 1,
      external_uid: '46280ab5-b5d7-4689-8ca1-9b418e13d3cf',
      url: 'https://prod-quote-service-quote-quoterequestmediasbucket-3qvvyexd1b23.s3.sa-east-1.amazonaws.com/fecd8b3b-d676-4153-ba00-5e3b1425c81a.jpeg',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
      media_type: 'IMAGE',
      media_name: 'fecd8b3b-d676-4153-ba00-5e3b1425c81a.jpeg',
    },
  ],
  quote_request_audios: [
    {
      id: 2,
      external_uid: '5c6d16fc-d29c-4e4b-82a4-a494dce3ce5a',
      url: 'https://prod-quote-service-quote-quoterequestmediasbucket-3qvvyexd1b23.s3.sa-east-1.amazonaws.com/d7e51581-7d8b-4638-bc76-d7002f1dbf4a.ogg',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
      media_type: 'AUDIO',
      media_name: 'd7e51581-7d8b-4638-bc76-d7002f1dbf4a.ogg',
    },
    {
      id: 3,
      external_uid: '03c89eff-9739-453d-962b-2a5264dc3243',
      url: 'https://prod-quote-service-quote-quoterequestmediasbucket-3qvvyexd1b23.s3.sa-east-1.amazonaws.com/9dbe1664-c074-4e67-b88e-febe92aecbd9.ogg',
      created_at: '2022-04-13T23:53:03.868Z',
      updated_at: '2022-04-13T23:53:03.872Z',
      is_active: true,
      quote_request_id: 1,
      media_type: 'AUDIO',
      media_name: '9dbe1664-c074-4e67-b88e-febe92aecbd9.ogg',
    },
  ],
};

const itemsOffersResult: Array<any> = [
  [
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TECFIL',
      offer_factory_code: 'PSL34M',
      estimated_purchase_unit_price: 11.96,
      description: 'FILTRO OLEO LUBRIFICANTE TM1 PSL34M TECFIL',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 235,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TECFIL_PSL34M__jpg?noCache=caf853b1-5e0b-4d7a-bdaf-7927ae26f7d2',
      offer_provider_reference: 2904861,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 17.23,
      offer_hash: '14a04d02',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MANN',
      offer_factory_code: 'W7MULTI34S',
      estimated_purchase_unit_price: 12.78,
      description: 'FILTRO OLEO LUBRIFICANTE - W7MULTI34S MANN',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 48,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/MANN_W7MULTI34S__jpg?noCache=98061847-e3c4-468c-a86b-12f94501996e',
      offer_provider_reference: 2904960,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 18.02,
      offer_hash: 'da78e382',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TECFIL',
      offer_factory_code: 'PSL144',
      estimated_purchase_unit_price: 19.06,
      description: 'FILTRO OLEO LUBRIFICANTE - PSL144 TECFIL',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 14,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TECFIL_PSL144__JPG?noCache=980e8400-d267-41c1-adeb-0d656d7423e2',
      offer_provider_reference: 2904828,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 24.11,
      offer_hash: '2c63b2f5',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'BOSCH',
      offer_factory_code: '0986B00003',
      estimated_purchase_unit_price: 19.61,
      description: 'FILTRO OLEO LUBRIFICANTE OB0003 0986B00003 BOSCH',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 4,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/BOSCH_0986B00003__jpg?noCache=6f8dc20d-918c-463c-af59-2afc2ab1a1ae',
      offer_provider_reference: 2903134,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 24.65,
      offer_hash: 'a32045d0',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MANN',
      offer_factory_code: 'W7015',
      estimated_purchase_unit_price: 25.42,
      description: 'FILTRO OLEO LUBRIFICANTE - W7015 MANN',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 20,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/MANN_W7015__jpg?noCache=c9bc43a2-9ce6-4ab3-99dd-2cbee017e08e',
      offer_provider_reference: 3142898,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 30.28,
      offer_hash: '04588012',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FRAM',
      offer_factory_code: 'PH10907',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - PH10907 FRAM',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FRAM_PH10907__jpg?noCache=5a454f01-9e7f-41f6-b8e3-69e8efaf3aac',
      offer_provider_reference: 2904654,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '074726d6',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FRAM',
      offer_factory_code: 'PH9566',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO - PH9566 FRAM',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FRAM_PH9566__jpg?noCache=ae1603f1-fa41-4ecc-b93d-94836bd3689c',
      offer_provider_reference: 2949509,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '810ac6da',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WIX',
      offer_factory_code: 'WL54025',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - WL54025 WIX',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WIX_WL54025__jpg?noCache=d6abfa9b-ede4-4788-b4a2-714119fb7ae7',
      offer_provider_reference: 3212608,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '458b3a45',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'PUROLATOR',
      offer_factory_code: 'L1065',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - L1065 PUROLATOR',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/produtoNotFound',
      offer_provider_reference: 3246958,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '89291cad',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MTECH',
      offer_factory_code: '210017MTE00',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO 210017MTE00 MTECH',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/produtoNotFound',
      offer_provider_reference: 3677142,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'e099b3f1',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MTECH',
      offer_factory_code: '210037MTE00',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO 210037MTE00 MTECH',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/produtoNotFound',
      offer_provider_reference: 3677156,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '6947fdb1',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'BOSCH',
      offer_factory_code: '0986B01034',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO OB1034 0986B01034 BOSCH',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/BOSCH_0986B01034__jpg?noCache=e3c8bf31-bb6c-472e-bbd5-2fed5073b674',
      offer_provider_reference: 3706479,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'e050ad88',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MAHLE',
      offer_factory_code: 'OC1446',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - OC1446 MAHLE',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/MAHLE_OC1446__JPG?noCache=ddb637d3-be13-419e-8645-694ad6afe7b6',
      offer_provider_reference: 3751062,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '21f9ee0c',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FILTROS VOX',
      offer_factory_code: 'LB144',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - LB144 FILTROS VOX',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FILTROS%20VOX_LB144__JPG?noCache=0b01089a-2ec4-41ee-98c1-791b8d900098',
      offer_provider_reference: 3755580,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'ef30f377',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FILTROS VOX',
      offer_factory_code: 'LBM1',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - LBM1 FILTROS VOX',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FILTROS%20VOX_LBM1__JPG?noCache=d5a344cc-17eb-43a1-b479-10f01df13ec6',
      offer_provider_reference: 3755651,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '126d100c',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'DELPHI',
      offer_factory_code: 'EFL189',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - EFL189 DELPHI',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/DELPHI_EFL189__JPG?noCache=aff66d13-07d5-4309-9429-4b9932791df2',
      offer_provider_reference: 3758413,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '90a92128',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'DELPHI',
      offer_factory_code: 'EFL750',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - EFL750 DELPHI',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/DELPHI_EFL750__JPG?noCache=3cdba72f-dbd7-4d1c-9b76-d1a19307ca88',
      offer_provider_reference: 3758460,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'd2751f4f',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FRAM',
      offer_factory_code: 'PH4482',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - PH4482 FRAM',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FRAM_PH4482__jpg?noCache=73f684c5-fac1-4538-9ee0-0d868b468393',
      offer_provider_reference: 3865671,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '9456ab90',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'JAPANPARTS',
      offer_factory_code: 'FOBR8S',
      estimated_purchase_unit_price: 0,
      description: 'FILTRO OLEO LUBRIFICANTE - FOBR8S JAPANPARTS',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/produtoNotFound',
      offer_provider_reference: 4156896,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'cdd716aa',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MAHLE',
      offer_factory_code: 'OC506',
      estimated_purchase_unit_price: 12.63,
      description: 'FILTRO OLEO LUBRIFICANTE - OC506 MAHLE',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/MAHLE_OC506__jpg?noCache=63471849-edd9-4373-b0fc-2ca62626694a',
      offer_provider_reference: 2904560,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 17.88,
      offer_hash: 'f8f3bbf1',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'BOSCH',
      offer_factory_code: '0986B00017',
      estimated_purchase_unit_price: 22.29,
      description: 'FILTRO OLEO LUBRIFICANTE OB0017 0986B00017 BOSCH',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/BOSCH_0986B00017__jpg?noCache=52a0dea5-0af1-4db8-a7e1-6f9420085e0a',
      offer_provider_reference: 2903145,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 27.25,
      offer_hash: '2414a09c',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MAHLE',
      offer_factory_code: 'OC266',
      estimated_purchase_unit_price: 26.9,
      description: 'FILTRO OLEO LUBRIFICANTE - OC266 MAHLE',
      price_tracked_at: '2022-04-20T21:55:13.842Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/MAHLE_OC266__jpg?noCache=cdfa2d63-3aa4-41fa-bed3-383566a61eeb',
      offer_provider_reference: 2904534,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 31.72,
      offer_hash: 'b14e80d7',
      points: 0,
    },
  ],
  [
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'LONAFLEX',
      offer_factory_code: 'P767',
      estimated_purchase_unit_price: 91.32,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - P767 LONAFLEX',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 1,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/LONAFLEX_P767__jpg?noCache=ce59e74b-4267-4b19-b9cf-3ffe3e5cf382',
      offer_provider_reference: 2905607,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 94.21,
      offer_hash: 'e9b6563c',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'COBREQ',
      offer_factory_code: 'N161',
      estimated_purchase_unit_price: 116.42,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - N161 COBREQ',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 6,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/COBREQ_N161__jpg?noCache=6748af05-42f3-491b-8c3c-5aa3a1b2cc85',
      offer_provider_reference: 2905332,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 118.55,
      offer_hash: 'aecb08cd',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FRAS-LE',
      offer_factory_code: 'PD767',
      estimated_purchase_unit_price: 142.03,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - PD767 FRAS-LE',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 2,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FRAS-LE_PD767__JPG?noCache=0b9440e5-274a-4c18-a0f6-2b3f7732cd07',
      offer_provider_reference: 3865973,
      delivery_type: 'express_delivery',
      is_available: true,
      client_unit_price: 143.4,
      offer_hash: '256b76a3',
      points: 3,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TRW',
      offer_factory_code: 'RCPT04840',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA GIRLING - RCPT04840 TRW',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/produtoNotFound',
      offer_provider_reference: 2905994,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '5f3cd6a4',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'ECOPADS',
      offer_factory_code: 'ECO1485',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TEVES - ECO1485 ECOPADS',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/ECOPADS_ECO1485__JPG?noCache=12e57668-6c11-41af-956d-31c1de5cbb5a',
      offer_provider_reference: 3032925,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '4710758a',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'LONAFLEX',
      offer_factory_code: 'P796',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL TRASEIRA - - P796 LONAFLEX',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/LONAFLEX_P796__jpg?noCache=38b28abe-2637-43dc-8477-03a22bb0ee3d',
      offer_provider_reference: 3050826,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'c46b556e',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'BENDIX',
      offer_factory_code: 'HQ2263',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TRW - HQ2263 BENDIX',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/BENDIX_HQ2263__jpg?noCache=33b173ad-f50a-40aa-b559-b7da484e62b4',
      offer_provider_reference: 3146681,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '51ba4ec6',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'JURID',
      offer_factory_code: 'HQJ2263',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TEVES - HQJ2263 JURID',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/JURID_HQJ2263__JPG?noCache=eda0185c-064c-4dc4-9421-bffb9e765e83',
      offer_provider_reference: 3172793,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'eb1d2d5e',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'ATE',
      offer_factory_code: '8169',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TRW - 8169 ATE',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/ATE_8169__jpg?noCache=318ac90f-231c-4a20-b6fa-e35936314173',
      offer_provider_reference: 3188510,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'c01619a9',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'NAKATA',
      offer_factory_code: 'NKF1135P',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - NKF1135P NAKATA',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/NAKATA_NKF1135P__jpg?noCache=c2cedc97-500f-40c2-a68f-cf96fccf117a',
      offer_provider_reference: 3216921,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'dab7c6fa',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'NAKATA',
      offer_factory_code: 'NKF1141P',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TEVES - NKF1141P NAKATA',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/NAKATA_NKF1141P__jpg?noCache=9fb2ea31-3509-4438-a8fc-32d05fbe25f1',
      offer_provider_reference: 3216924,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '34c5519b',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'REMSA',
      offer_factory_code: '069100',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA ATE/TEVES - 069100 REMSA',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/REMSA_069100__JPG?noCache=321b908d-35de-4f3a-b7db-1ece0da0d224',
      offer_provider_reference: 3423067,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '0dbd0c85',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'REMSA',
      offer_factory_code: '084220',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA ATE/TEVES - 084220 REMSA',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/REMSA_084220__JPG?noCache=5e60486b-b34c-48e6-bf22-2c13d19050b7',
      offer_provider_reference: 3423103,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'c62f875a',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'ECOPADS',
      offer_factory_code: 'ECO1233',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - ECO1233 ECOPADS',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/ECOPADS_ECO1233__JPG?noCache=82f514d1-3158-4ec0-8ac3-b68e6675668c',
      offer_provider_reference: 3598876,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '901567c7',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'ECOPADS',
      offer_factory_code: 'ECO1522',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - ECO1522 ECOPADS',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/ECOPADS_ECO1522__JPG?noCache=86a88dc0-2470-45e6-b865-4a6877302ec9',
      offer_provider_reference: 3599010,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '9639388a',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FERODO',
      offer_factory_code: 'HQF2136C',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CERAMICA DIANTEIRA - SISTEMA TEVES - HQF2136C FERODO',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FERODO_HQF2136C__JPG?noCache=2601a9ae-0a86-49df-94e6-a980fb92a435',
      offer_provider_reference: 3610537,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '862d55a9',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FERODO',
      offer_factory_code: 'HQF2263C',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CERAMICA TRASEIRA - SISTEMA TEVES - HQF2263C FERODO',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FERODO_HQF2263C__JPG?noCache=9a028b16-c133-4b83-befc-ff7581c96af6',
      offer_provider_reference: 3610571,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '8080799f',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FERODO',
      offer_factory_code: 'HQF3011PAC',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CERAMICA DIANTEIRA C/ ALARME SISTEMA TEVES - HQF3011PAC FERODO',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FERODO_HQF3011PAC__JPG?noCache=aabd048c-6b42-4e7d-81ab-37c2e56437bd',
      offer_provider_reference: 3610650,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '57297f69',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'MOTORCRAFT',
      offer_factory_code: 'MB1A2K021HB',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - - MB1A2K021HB MOTORCRAFT',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/produtoNotFound',
      offer_provider_reference: 3670621,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '2fa78a1c',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'FP732',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA/TRASEIRA - - FP732 WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_FP732__jpg?noCache=68b67b9a-0b98-44dc-a42b-0e5f975f814f',
      offer_provider_reference: 3698253,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '31f78981',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'FP708PA',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL DIANTEIRA - - FP708PA WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_FP708PA__jpg?noCache=65fb1346-a1a4-4a5d-b983-1edcf691601c',
      offer_provider_reference: 3698447,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '496245ba',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'FP733',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL DIANTEIRA - - FP733 WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_FP733__jpg?noCache=eb113df0-7d8d-4b08-ba92-e299a35cf5f1',
      offer_provider_reference: 3698448,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'ace82445',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'FP442',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL DIANTEIRA - - FP442 WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_FP442__jpg?noCache=33917f24-ecb5-452f-9952-6b8da036eb1b',
      offer_provider_reference: 3698519,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '0412f953',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'PW708PA',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL DIANTEIRA - - PW708PA WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_PW708PA__jpg?noCache=560e3af9-d57c-404f-a45f-ec9033b58d57',
      offer_provider_reference: 3698755,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '34bc48ee',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'PW732',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TEVES - PW732 WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_PW732__jpg?noCache=47a48914-0f00-4c98-971b-6e324d46a993',
      offer_provider_reference: 3698814,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '030d2839',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'PW733',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - PW733 WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_PW733__jpg?noCache=c69471f9-b86e-4396-a728-04707bfa84fb',
      offer_provider_reference: 3698876,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '779a7f21',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'WILLTEC',
      offer_factory_code: 'PW442',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - PW442 WILLTEC',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/WILLTEC_PW442__jpg?noCache=42e385f4-e63b-4136-b7d8-639d062002c7',
      offer_provider_reference: 3698916,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'b217a698',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'ATE',
      offer_factory_code: '607193',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL DIANTEIRA - - 607193 ATE',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/ATE_607193__jpg?noCache=89292dd3-6377-4850-8a8a-958abe4812bb',
      offer_provider_reference: 3705857,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '9cf3c845',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'ATE',
      offer_factory_code: '607195',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL TRASEIRA - - 607195 ATE',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/ATE_607195__jpg?noCache=da36344a-9e61-40a4-8e47-201b5d93c595',
      offer_provider_reference: 3705858,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '412d2fd4',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'JURID',
      offer_factory_code: 'HQJ2136',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - HQJ2136 JURID',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/JURID_HQJ2136__jpg?noCache=d1fef0ea-04b9-425e-82b1-95360c76c08a',
      offer_provider_reference: 3729646,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '83e3f497',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'STOP',
      offer_factory_code: 'HQS2136',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - HQS2136 STOP',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/STOP_HQS2136__jpg?noCache=e1a92520-5e11-463e-9838-577365208285',
      offer_provider_reference: 3729778,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '0ddc7a82',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TK FREIOS',
      offer_factory_code: 'TK697',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA/TRASEIRA - SISTEMA TEVES - TK697 TK FREIOS',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TK%20FREIOS_TK697__JPG?noCache=b6ed02b5-ca4b-434c-a780-e06d346ca5fe',
      offer_provider_reference: 3733664,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '038bd2e0',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'SINTER FREIOS',
      offer_factory_code: 'FO3121',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - FO3121 SINTER FREIOS',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/SINTER%20FREIOS_FO3121__JPG?noCache=c28e4e55-ec0f-4974-8f4a-33f5b7e00353',
      offer_provider_reference: 3735040,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'c7fe80a1',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'SINTER FREIOS',
      offer_factory_code: 'FO3144',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - FO3144 SINTER FREIOS',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/SINTER%20FREIOS_FO3144__JPG?noCache=4e0f487e-4bc2-494f-88c4-013d89a3fdae',
      offer_provider_reference: 3735053,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'e32b8de6',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'SPEED BRAKE',
      offer_factory_code: 'SNA168',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TEVES - SNA168 SPEED BRAKE',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/SPEED%20BRAKE_SNA168__JPG?noCache=bfb080d2-6661-43a5-aef5-c4748ada52e7',
      offer_provider_reference: 3737425,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '2371d042',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'SYL',
      offer_factory_code: '2240C',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CERAMICA DIANTEIRA - SISTEMA TEVES - 2240C SYL',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/SYL_2240C__JPG?noCache=9edce8a6-0996-4e5d-878e-c11c866f588f',
      offer_provider_reference: 3744822,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '5774329e',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'COFAP',
      offer_factory_code: 'PFC080008',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA GIRLING - PFC080008 COFAP',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/COFAP_PFC080008__JPG?noCache=2c8a6425-11a8-479a-bf35-70c0141b5825',
      offer_provider_reference: 3816164,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '6096ac2a',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'COFAP',
      offer_factory_code: 'PFC080031',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - PFC080031 COFAP',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/COFAP_PFC080031__JPG?noCache=45f64057-a036-4396-98fe-9aa314396bdd',
      offer_provider_reference: 3816184,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '46c57e9a',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'COFAP',
      offer_factory_code: 'PFC080036',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - PFC080036 COFAP',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/COFAP_PFC080036__JPG?noCache=ba9e58d8-233a-42f7-b75e-65d87af9a541',
      offer_provider_reference: 3816187,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'e0433ad1',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TEXTAR',
      offer_factory_code: '2372301',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - 2372301 TEXTAR',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TEXTAR_2372301__jpg?noCache=48af794c-224b-4b23-b422-c36d7cb3f570',
      offer_provider_reference: 3929163,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'af1e92c7',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TEXTAR',
      offer_factory_code: '2391201',
      estimated_purchase_unit_price: 0,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - 2391201 TEXTAR',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TEXTAR_2391201__jpg?noCache=c043e553-3420-47e7-8421-59b99fc465a8',
      offer_provider_reference: 3929175,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: 'af094121',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TRW',
      offer_factory_code: 'GDB2033',
      estimated_purchase_unit_price: 0,
      description: 'PASTILHA FREIO CONVENCIONAL TRASEIRA - - - GDB2033 TRW',
      price_tracked_at: '2022-04-20T21:55:14.913Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/produtoNotFound',
      offer_provider_reference: 4135640,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 0,
      offer_hash: '21d96fb4',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'SYL',
      offer_factory_code: '1241',
      estimated_purchase_unit_price: 37.94,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA GIRLING - 1241 SYL',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/SYL_1241__jpg?noCache=b4275795-7bd1-43c7-9a79-37f2e8df1bd8',
      offer_provider_reference: 3185048,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 42.43,
      offer_hash: '3adbe524',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TRW',
      offer_factory_code: 'RCPT12240',
      estimated_purchase_unit_price: 68.98,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA ATE/TEVES - RCPT12240 TRW',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TRW_RCPT12240__JPG?noCache=67fa6cf3-3d65-4767-909e-1c86a42bd68b',
      offer_provider_reference: 3078117,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 72.54,
      offer_hash: '5807fde0',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'COBREQ',
      offer_factory_code: 'N163',
      estimated_purchase_unit_price: 78.85,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA TEVES - N163 COBREQ',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/COBREQ_N163__jpg?noCache=eafb65b1-7264-4f23-867e-cc9d02849a99',
      offer_provider_reference: 2905333,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 82.11,
      offer_hash: '514cfb5e',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'SYL',
      offer_factory_code: '2240',
      estimated_purchase_unit_price: 82.22,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA TEVES - 2240 SYL',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/SYL_2240__jpg?noCache=45b06372-ac0c-4701-90e2-4a22b6fefe70',
      offer_provider_reference: 3188801,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 85.38,
      offer_hash: 'd2a34f0a',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TRW',
      offer_factory_code: 'RCPT13200',
      estimated_purchase_unit_price: 93.49,
      description:
        'PASTILHA FREIO CONVENCIONAL TRASEIRA - SISTEMA ATE/TEVES - RCPT13200 TRW',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TRW_RCPT13200__JPG?noCache=d49d2072-5e6b-411a-9073-4348a1ea2a9a',
      offer_provider_reference: 3078207,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 96.31,
      offer_hash: '294ac9b6',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'TRW',
      offer_factory_code: 'RCPT02890',
      estimated_purchase_unit_price: 97.48,
      description:
        'PASTILHA FREIO CONVENCIONAL DIANTEIRA - SISTEMA ATE/TEVES - RCPT02890 TRW',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/TRW_RCPT02890__JPG?noCache=025d5bc8-6087-4ba5-986e-fa70af823b50',
      offer_provider_reference: 2905953,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 100.18,
      offer_hash: '63454ca6',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FRAS-LE',
      offer_factory_code: 'PD796',
      estimated_purchase_unit_price: 107.81,
      description: 'PASTILHA FREIO CONVENCIONAL TRASEIRA - - - PD796 FRAS-LE',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FRAS-LE_PD796__JPG?noCache=1c0a45ab-4bd8-446b-9433-9f109a5b064f',
      offer_provider_reference: 3865202,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 110.2,
      offer_hash: '80257a03',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FRAS-LE',
      offer_factory_code: 'PD796CMAXX',
      estimated_purchase_unit_price: 153.78,
      description: 'PASTILHA FREIO CERAMICA TRASEIRA - - - PD796CMAXX FRAS-LE',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FRAS-LE_PD796CMAXX__jpg?noCache=74b2ddbb-be92-4ee4-91ef-f1af434c17e2',
      offer_provider_reference: 3573450,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 154.79,
      offer_hash: '94b076a2',
      points: 0,
    },
    {
      offer_provider_name: 'DPK',
      offer_brand_name: 'FRAS-LE',
      offer_factory_code: 'PD767CMAXX',
      estimated_purchase_unit_price: 207.18,
      description:
        'PASTILHA FREIO CERAMICA DIANTEIRA - SISTEMA TEVES - PD767CMAXX FRAS-LE',
      price_tracked_at: '2022-04-20T21:55:14.912Z',
      stock_units: 0,
      offer_image_url:
        'https://api-unificado.kdapeca.com.br/img/produto/FRAS-LE_PD767CMAXX__jpg?noCache=976e4f99-236d-4b07-ae65-05083dfab267',
      offer_provider_reference: 3573449,
      delivery_type: 'normal_delivery',
      is_available: false,
      client_unit_price: 206.59,
      offer_hash: 'fbb92abc',
      points: 0,
    },
  ],
];

const searchVehicleByPlateResponse: any = {
  vehicle: {
    id: 1388,
    external_uid: '516f8ea0-0d8c-46a2-ba6e-8d4f6cebdfbe',
    plate: 'fhd8741',
    color: null,
    kilometer: null,
    vin: '8AFSZZFHCGJ431851',
    engine_power: '2.0',
    created_at: '2022-04-21T14:31:22.683Z',
    updated_at: '2022-04-21T14:31:22.687Z',
    is_active: true,
    needs_review_brand: false,
    needs_review_model: false,
    needs_review_version: false,
    vehicle_template_id: 222312,
  },
  vehiclePlateRawInfo: {
    bodyStyle: 'NAO APLICAVEL',
    colour: 'Branca',
    engineCapacity: '1999',
    engineCode: 'U4DAGJ431851',
    engineCodes: ['U4DAGJ431851'],
    fuel: 'Flex',
    mark: 'FORD',
    mfrYear: '2016',
    model: 'FOCUS TI AT 2.0 HC Flex',
    modelYear: 2016,
    power: 178,
    vin: '8AFSZZFHCGJ431851',
    alternativeModel: 'FORD FOCUS III 2.0 SE Powershift Direct Flex',
    enginePower: '2.0',
  },
  vehicleTemplateResult: [
    {
      _index: 'vehicle-versions-index-000001',
      _type: '_doc',
      _id: 'xuGEPX8BDnYqk7nHzxOI',
      _score: 43.64828,
      _source: {
        id: 222312,
        sku: '18e5ab9b-bc04-4566-a4d9-74f0a6120477',
        manufacture_year: 2016,
        model_year: 2016,
        fuel: 'Flex',
        is_active: true,
        vehicle_brand_id: 303,
        vehicle_model_id: 3528,
        vehicle_version_id: 26423,
        vehicle_template_id: 222312,
        version_name: 'Sedan 2.0 16V/2.0 16V Flex 4p Aut.',
        vehicle_brand_name: 'Ford',
        vehicle_model_name: 'Focus',
        vehicle_token:
          'Sedan 2.0 16V/2.0 16V Flex 4p Aut. Ford Focus 2016 2016',
      },
    },
    {
      _index: 'vehicle-versions-index-000001',
      _type: '_doc',
      _id: 'JeGEPX8BDnYqk7nHyBB7',
      _score: 41.886265,
      _source: {
        id: 221383,
        sku: '7274874b-809c-430c-bcd0-b1507d693330',
        manufacture_year: 2016,
        model_year: 2016,
        fuel: 'Flex',
        is_active: true,
        vehicle_brand_id: 303,
        vehicle_model_id: 3528,
        vehicle_version_id: 26413,
        vehicle_template_id: 221383,
        version_name: 'Fastback TIT./T.PLUS 2.0 Flex Aut.',
        vehicle_brand_name: 'Ford',
        vehicle_model_name: 'Focus',
        vehicle_token:
          'Fastback TIT./T.PLUS 2.0 Flex Aut. Ford Focus 2016 2016',
      },
    },
    {
      _index: 'vehicle-versions-index-000001',
      _type: '_doc',
      _id: '6uGEPX8BDnYqk7nHyA8j',
      _score: 41.381435,
      _source: {
        id: 221324,
        sku: 'f3d8b8bc-ce97-4203-9da8-148b1cdeef85',
        manufacture_year: 2016,
        model_year: 2016,
        fuel: 'Flex',
        is_active: true,
        vehicle_brand_id: 303,
        vehicle_model_id: 3528,
        vehicle_version_id: 26412,
        vehicle_template_id: 221324,
        version_name: 'Fastback SE/SE PLUS 2.0 Flex Aut.',
        vehicle_brand_name: 'Ford',
        vehicle_model_name: 'Focus',
        vehicle_token: 'Fastback SE/SE PLUS 2.0 Flex Aut. Ford Focus 2016 2016',
      },
    },
    {
      _index: 'vehicle-versions-index-000001',
      _type: '_doc',
      _id: 'guGEPX8BDnYqk7nH0BTo',
      _score: 41.381435,
      _source: {
        id: 222500,
        sku: '1f8d70ba-4cb5-42e1-b503-8ec5762dbd38',
        manufacture_year: 2016,
        model_year: 2016,
        fuel: 'Flex',
        is_active: true,
        vehicle_brand_id: 303,
        vehicle_model_id: 3528,
        vehicle_version_id: 26424,
        vehicle_template_id: 222500,
        version_name: 'TITA/TITA Plus 2.0  Flex 5p Aut.',
        vehicle_brand_name: 'Ford',
        vehicle_model_name: 'Focus',
        vehicle_token: 'TITA/TITA Plus 2.0  Flex 5p Aut. Ford Focus 2016 2016',
      },
    },
    {
      _index: 'vehicle-versions-index-000001',
      _type: '_doc',
      _id: 'NOGEPX8BDnYqk7nHxg_j',
      _score: 40.91563,
      _source: {
        id: 221142,
        sku: '9183cc70-722d-4482-b950-e6133de3a333',
        manufacture_year: 2016,
        model_year: 2016,
        fuel: 'Flex',
        is_active: true,
        vehicle_brand_id: 303,
        vehicle_model_id: 3528,
        vehicle_version_id: 26411,
        vehicle_template_id: 221142,
        version_name: '2.0 16V/SE/SE Plus Flex 5p Aut.',
        vehicle_brand_name: 'Ford',
        vehicle_model_name: 'Focus',
        vehicle_token: '2.0 16V/SE/SE Plus Flex 5p Aut. Ford Focus 2016 2016',
      },
    },
    {
      _index: 'vehicle-versions-index-000001',
      _type: '_doc',
      _id: 'ceGEPX8BDnYqk7nHwg3y',
      _score: 34.345818,
      _source: {
        id: 220691,
        sku: '8eeb9dc0-7a85-4df9-a5ad-3eb23584bc52',
        manufacture_year: 2016,
        model_year: 2016,
        fuel: 'Flex',
        is_active: true,
        vehicle_brand_id: 303,
        vehicle_model_id: 3528,
        vehicle_version_id: 26407,
        vehicle_template_id: 220691,
        version_name: '1.6 S/1.6 SE Flex 16v 5p Aut',
        vehicle_brand_name: 'Ford',
        vehicle_model_name: 'Focus',
        vehicle_token: '1.6 S/1.6 SE Flex 16v 5p Aut Ford Focus 2016 2016',
      },
    },
    {
      _index: 'vehicle-versions-index-000001',
      _type: '_doc',
      _id: '0eGEPX8BDnYqk7nHww2j',
      _score: 34.15913,
      _source: {
        id: 220787,
        sku: '391937b0-c698-4bc4-913f-4d7e0b5c67e4',
        manufacture_year: 2016,
        model_year: 2016,
        fuel: 'Flex',
        is_active: true,
        vehicle_brand_id: 303,
        vehicle_model_id: 3528,
        vehicle_version_id: 26408,
        vehicle_template_id: 220787,
        version_name: '1.6 S/SE/SE Plus Flex 8V/16V  5p',
        vehicle_brand_name: 'Ford',
        vehicle_model_name: 'Focus',
        vehicle_token: '1.6 S/SE/SE Plus Flex 8V/16V  5p Ford Focus 2016 2016',
      },
    },
  ],
};

const quoteRequestItemsResponse: Array<any> = [
  {
    id: 3,
    uid: 'e2b04180-6579-4a41-9237-a1e72558ee17',
    slug: "bomba-d'agua",
    description: "bomba d'agua",
    created_at: '2022-04-20T18:27:04.674Z',
    updated_at: '2022-04-20T18:27:04.676Z',
    is_active: true,
    quote_request_id: 2,
  },
  {
    id: 4,
    uid: 'e2edd63a-772f-474a-bd8c-1c20bf166389',
    slug: 'filtro-de-combustivel',
    description: 'filtro de combustível',
    created_at: '2022-04-20T18:27:04.674Z',
    updated_at: '2022-04-20T18:27:04.676Z',
    is_active: true,
    quote_request_id: 2,
  },
];

export {
  defaultMessages,
  defaultUserInfos,
  defaultAlerts,
  defaultLogs,
  messageFromClient,
  itemsOffersResult,
  quoteRequest,
  searchVehicleByPlateResponse,
  quoteRequestItemsResponse,
};
