import { QuoteRequest } from "app/models";

const checkIfQuoteRequestHasVehicle = (quoteRequest:QuoteRequest) => {
  let hasVehicle = false;
  if (quoteRequest?.vehicle_plate || quoteRequest?.vehicle_brand_uid) {
    hasVehicle = true;
  }  
  return hasVehicle;
}


export {
  checkIfQuoteRequestHasVehicle
}