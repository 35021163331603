import { User } from './User';

export enum AccountUserRoleEnum {
    ROLE_MECANIZOU_ADMIN = <any>"Administrador",
    ROLE_MECANIZOU_MEMBER = <any>"Membro",
    ROLE_MECHANIC_SHOP_OWNER = <any>"Proprietário oficina",
    ROLE_MECHANIC_SHOP_MEMBER = <any>"Membro oficina",
    ROLE_AUTOPART_OWNER = <any>"Proprietário autopeça",
    ROLE_AUTOPART_MEMBER = <any>"Membro autopeça",
    ROLE_USER = <any>"Usuário"
}

export type AccountUserRole = 
'ROLE_MECANIZOU_ADMIN' |
'ROLE_MECANIZOU_MEMBER' |
'ROLE_MECHANIC_SHOP_OWNER' |
'ROLE_MECHANIC_SHOP_MEMBER' |
'ROLE_AUTOPART_OWNER' |
'ROLE_AUTOPART_MEMBER' |
'ROLE_USER';

export type PersonalAccount = {
    name: string
    is_active:boolean
}

export type AccountUser = {
    user?: User
    id?: number
    uid: string
    account_id: number
    user_id: number
    role: AccountUserRole
    is_active: boolean
    createdAt: string
    updatedAt: string
    personal_account: PersonalAccount
}

export type BodyUpdateRoleAccountUser = {
    role?: 'ROLE_MECANIZOU_ADMIN' | 'ROLE_MECANIZOU_MEMBER'
}