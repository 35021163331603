import {Suspense, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {I18nProvider} from '../_metronic/i18n/i18nProvider';
import {
  LayoutProvider,
  LayoutSplashScreen,
  useLayout,
} from '../_metronic/layout/core';
import {MasterInit} from '../_metronic/layout/MasterInit';
import {AuthInit, useAuth} from './modules/auth';
import { datadog } from './utils/services/datadog';
import Hotjar from '@hotjar/browser';

const App = () => {
  const {setLayout, config} = useLayout();
  const {currentUser} = useAuth();


  useEffect(() => {
    config.header.width = 'fluid';
    setLayout(config);
    const clientToken = process.env.REACT_APP_DD_CLIENT_TOKEN ?? '';
    const applicationId = process.env.REACT_APP_DD_APPLICATION_ID ?? '';
    datadog.init(clientToken, applicationId);

    Hotjar.init(
      Number(process.env.REACT_APP_HOTJAR_HJID),
      Number(process.env.REACT_APP_HOTJAR_HJSV)
    );
  }, []);

  useEffect(() => {
    currentUser && datadog.identify(currentUser.uid);
  }, [currentUser])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export {App};
