import {RenegotiationResponse} from 'app/models';
import {
  PaymentBills,
  PaymentConditions,
  PaymentsListResponse,
} from 'app/models/Payment';
import {Transaction} from 'app/models/Transaction';
import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const PAYMENT_API_URL = `${API_URL}/payments`;
const TRANSACTIONS_API_URL = `${API_URL}/transactions`;

export const getPaymentBills = (
  params: string
): Promise<{result: PaymentBills[]; resultCount: number}> => {
  return axios
    .get(`${PAYMENT_API_URL}/payment-bills?${params}`)
    .then(
      (d: AxiosResponse<{result: PaymentBills[]; resultCount: number}>) =>
        d.data
    )
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const getTransactions = (
  params: string
): Promise<{result: Transaction[]; resultCount: number}> => {
  return axios
    .get(`${TRANSACTIONS_API_URL}?${params}`)
    .then(
      (d: AxiosResponse<{result: Transaction[]; resultCount: number}>) => d.data
    )
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const updatePaymentBill = (
  paymentBillUid: string,
  body: any
): Promise<PaymentBills> => {
  return axios
    .put(`${PAYMENT_API_URL}/payment-bills/${paymentBillUid}`, body)
    .then((d: AxiosResponse<PaymentBills>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const confirmPaymentTransaction = (
  paymentTransactionUid: string,
  body: {
    payment_date?: string;
    receipt?: string;
  }
): Promise<PaymentBills> => {
  return axios
    .put(
      `${PAYMENT_API_URL}/payment-transactions/${paymentTransactionUid}/confirm`,
      body
    )
    .then((d: AxiosResponse<PaymentBills>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const confirmTransaction = (
  paymentTransactionUid: string,
  body: {
    payment_date?: string;
    receipt?: string;
    fees?: number
    must_delete_payment_slip: boolean
  }
): Promise<PaymentBills> => {
  return axios
    .put(`${TRANSACTIONS_API_URL}/${paymentTransactionUid}/confirm`, body)
    .then((d: AxiosResponse<PaymentBills>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getPaymentConditions = (
  params?: string
): Promise<PaymentConditions[]> => {
  return axios
    .get(`${PAYMENT_API_URL}/payment-conditions?${params}`)
    .then((d: AxiosResponse<PaymentConditions[]>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const getRenegotiationPaymentConditions = (
  paymentsUidsArray: string[]
): Promise<RenegotiationResponse> => {
  return axios
    .get(
      `${PAYMENT_API_URL}/debt-renegotiation/${JSON.stringify(
        paymentsUidsArray
      )}/payment-conditions`
    )
    .then((d: AxiosResponse<RenegotiationResponse>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const deletePaymentBill = (uid: string): Promise<PaymentBills> => {
  return axios
    .delete(`${PAYMENT_API_URL}/payment-bills/${uid}`)
    .then((d: AxiosResponse<PaymentBills>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const createWeeklyPaymentBills = (body: {
  startDate: string | Date;
  endDate: string | Date;
}): Promise<string> => {
  return axios
    .post(`${PAYMENT_API_URL}/payment-bills`, body)
    .then((d: AxiosResponse<string>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const updatePaymentSlip = (
  paymentBillUid: string,
  body: any
): Promise<PaymentBills> => {
  return axios
    .put(
      `${PAYMENT_API_URL}/payment-bills/${paymentBillUid}/payment-slip`,
      body
    )
    .then((d: AxiosResponse<PaymentBills>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getPayments = (
  params?: string
): Promise<{result: PaymentsListResponse[]; resultCount: number}> => {
  return axios
    .get(`${PAYMENT_API_URL}?${params}`)
    .then(
      (
        d: AxiosResponse<{result: PaymentsListResponse[]; resultCount: number}>
      ) => {
        return d.data;
      }
    )
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const renegotiatePayments = (body: {
  paymentsUids: string[];
  paymentOptions: {
    payment_value: number,
    payment_condition_uid: string
  }[]
}): Promise<any> => {
  return axios
    .post(`${PAYMENT_API_URL}/renegotiation`, body)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => {
      throw e;
    });
};
