const getsAllItemsInfosForADelivery = (purchase_items: any[] | undefined) => {
  const referenceIds: (string | number)[] = [];
  let referenceIdsString: string = '';

  const providerReceiptReferences: string[] = [];
  let providerReceiptReferenceString = '';

  for (const {purchase_item} of purchase_items!) {
    const referenceId = purchase_item.order_item.orders?.reference_id;
    const providerReceiptReference =
      purchase_item.purchase.provider_receipt_reference;

    if (referenceId && !referenceIds.includes(referenceId)) {
      referenceIds?.push(referenceId);
      referenceIdsString += `#${referenceId} `;
    }

    if (
      providerReceiptReference &&
      !providerReceiptReferences.includes(providerReceiptReference)
    ) {
      providerReceiptReferenceString += providerReceiptReferences.length
        ? ` / ${providerReceiptReference}`
        : providerReceiptReference;
      providerReceiptReferences?.push(providerReceiptReference);
    }
  }

  return {
    referenceIds,
    referenceIdsString,
    providerReceiptReferences,
    providerReceiptReferenceString,
  };
};

const getsAllItemsInfosForAnIncidentItemDelivery = (
  incident_items: any[] | undefined
) => {
  const referenceIds: (string | number)[] = [];
  let referenceIdsString: string = '';

  const providerReceiptReferences: string[] = [];
  let providerReceiptReferenceString = '';

  for (const {incident_item} of incident_items!) {
    const referenceId =
      incident_item.purchase_item.order_item.orders?.reference_id;
    const providerReceiptReference =
      incident_item.purchase_item.purchase.provider_receipt_reference;

    if (referenceId && !referenceIds?.includes(referenceId)) {
      referenceIds?.push(referenceId);
      referenceIdsString += `#${referenceId} `;
    }

    if (
      providerReceiptReference &&
      !providerReceiptReferences?.includes(providerReceiptReference)
    ) {
      providerReceiptReferenceString += providerReceiptReferences.length
        ? ` / ${providerReceiptReference}`
        : providerReceiptReference;
      providerReceiptReferences?.push(providerReceiptReference);
    }
  }

  return {
    referenceIds,
    referenceIdsString,
    providerReceiptReferences,
    providerReceiptReferenceString,
  };
};

export {
  getsAllItemsInfosForADelivery,
  getsAllItemsInfosForAnIncidentItemDelivery,
};
