import {LogisticsPrice} from 'app/models';
import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const PRICING_API_URL = `${API_URL}/pricing`;

export const calculateLogisticsPrice = async (
  params: string
): Promise<LogisticsPrice> => {
  return await axios
    .get(`${PRICING_API_URL}/logistics?${params}`)
    .then((d: AxiosResponse<LogisticsPrice>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};
