import axios, {AxiosResponse} from 'axios';
import {QuoteRequest, QuoteRequestsQueryResponse} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_QUOTEREQUESTS_URL = `${API_URL}/quote-requests`;

export const getQuoteRequests = (
  query: string
): Promise<{result: QuoteRequestsQueryResponse; resultCount: number}> => {
  return axios.get(`${GET_QUOTEREQUESTS_URL}?${query}`).then(
    (
      resp: AxiosResponse<{
        result: QuoteRequestsQueryResponse;
        resultCount: number;
      }>
    ) => resp.data
  );
};

export const showQuoteRequest = (uid: string): Promise<QuoteRequest> => {
  return axios
    .get(`${GET_QUOTEREQUESTS_URL}/${uid}`)
    .then((resp: AxiosResponse<QuoteRequest>) => resp.data);
};

export const listQuoteRequestWorkable = (): Promise<
  QuoteRequestsQueryResponse[]
> => {
  return axios
    .get(`${GET_QUOTEREQUESTS_URL}/workable`)
    .then((resp: AxiosResponse<QuoteRequestsQueryResponse[]>) => resp.data);
};

export const updateQuoteRequestVehicle = (
  orderUid: string,
  body: any
): Promise<QuoteRequest> => {
  return axios
    .put(`${GET_QUOTEREQUESTS_URL}/${orderUid}/vehicle`, body)
    .then((resp: AxiosResponse<QuoteRequest>) => resp.data);
};

export const createNewQuoteRequest = (body: any): Promise<any> => {
  return axios
    .post(`${GET_QUOTEREQUESTS_URL}`, body)
    .then((resp: AxiosResponse<any>) => resp.data);
};

export const updateQuoteRequestStatus = ({
  quoteRequestUid,
  status,
}: {
  quoteRequestUid: string;
  status: string;
}): Promise<QuoteRequest> => {
  return axios
    .put(`${GET_QUOTEREQUESTS_URL}/${quoteRequestUid}/status`, {
      status,
    })
    .then((d: AxiosResponse<QuoteRequest>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const pullQuoteRequestFromQueue = (): Promise<QuoteRequest> => {
  return axios
    .get(`${GET_QUOTEREQUESTS_URL}/pull`)
    .then((d: AxiosResponse<QuoteRequest>) => d.data)
    .catch((e) => {
      throw e;
    });
};
