import axios, {AxiosResponse} from 'axios';
import {Delivery, GroupedIncidentItems, IncidentItem} from 'app/models';

const API_URL = process.env.REACT_APP_API_URL;
const INCIDENTS_URL = `${API_URL}/incidents`;

type NewIncidentDelivery = {
  incident_items_uids: string[];
  logistics_provider_uid: string;
  logistics_order_id?: string;
  logistics_service_type?: string;
  logistics_price: number;
  tracking_link?: string;
  comments?: string;
};

export const getIncidentItems = (
  query: string
): Promise<{result: IncidentItem[]; resultCount: number}> => {
  return axios
    .get(`${INCIDENTS_URL}?${query}`)
    .then(
      (resp: AxiosResponse<{result: IncidentItem[]; resultCount: number}>) =>
        resp.data
    )
    .catch((e) => {
      throw e;
    });
};

export const getGroupedIncidenItems = (): Promise<GroupedIncidentItems[]> => {
  return axios
    .get(`${INCIDENTS_URL}/grouped-queued`)
    .then((resp: AxiosResponse<GroupedIncidentItems[]>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const confirmDeliveredItems = ({
  deliveryUid,
  incidentItems,
}: {
  deliveryUid: string;
  incidentItems: {[key: string]: number};
}): Promise<null> => {
  return axios
    .put(`${INCIDENTS_URL}/confirm-delivered-items/${deliveryUid}`, {
      orderItemsHashtable: incidentItems,
    })
    .then((resp: AxiosResponse<null>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const updateIncidentStatus = (
  uid: string,
  body: {[key: string]: string}
): Promise<IncidentItem> => {
  return axios
    .put(`${INCIDENTS_URL}/${uid}`, body)
    .then((resp: AxiosResponse<IncidentItem>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const registerIncidentDelivery = (
  body: NewIncidentDelivery
): Promise<Delivery> => {
  return axios
    .post(`${INCIDENTS_URL}/register-delivery`, body)
    .then((resp: AxiosResponse<Delivery>) => resp.data)

    .catch((e) => {
      throw e;
    });
};

export const updateIncidentItemTaxReceiptInfo = (
  uid: string,
  body: any
): Promise<IncidentItem> => {
  return axios
    .put(`${INCIDENTS_URL}/tax-receipt-info/${uid}`, body)
    .then((resp: AxiosResponse<IncidentItem>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const cancelIncidentDelivery = (
  deliveryUid: string
): Promise<IncidentItem> => {
  return axios
    .put(`${INCIDENTS_URL}/${deliveryUid}/revert-cancelation`)
    .then((resp: AxiosResponse<IncidentItem>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const revertIncidentItem = (
  orderItemUid: string
): Promise<IncidentItem> => {
  return axios
    .put(`${INCIDENTS_URL}/${orderItemUid}/deactivate-incident-item`)
    .then((resp: AxiosResponse<IncidentItem>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const updateRefundStatusIncidentItem = (
  uid: string,
  body: {refund_status: 'approved' | 'denied'}
): Promise<IncidentItem> => {
  return axios
    .put(`${INCIDENTS_URL}/${uid}/refund-status`, body)
    .then((resp: AxiosResponse<IncidentItem>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const generateProviderReturnTaxReceipt = ({
  submissionInfo,
  taxReceiptObservations,
}: {
  submissionInfo: Array<{uid: string; quantity: number}>;
  taxReceiptObservations: {
    cancelationReason: string | undefined;
    purchaseId: string;
    devolutionCode: string | undefined;
    itemStorageLocation: string;
    additionalComments: string | undefined;
  };
}): Promise<string> => {
  let body: Record<string, any> = {
    incident_items: submissionInfo,
    tax_receipt_observations: taxReceiptObservations,
  };
  return axios
    .post(`${INCIDENTS_URL}/incident-tax-receipt-info`, {
      ...body,
    })
    .then((d: AxiosResponse<string>) => d.data)
    .catch((e) => {
      console.error(e);
      throw e;
    });
};
