export const cleanEmptyAndDuplicatedValues = <ItemType> (list: Array<ItemType>) => {
  const cleanList:ItemType[] = [];

  for(const item of list){
    if(item !== undefined && !cleanList.includes(item)){
      cleanList.push(item)
    }
  }

  return cleanList;
};