import axios, {AxiosResponse} from 'axios'
import { PurchaseItem } from '../models';

const API_URL = process.env.REACT_APP_API_URL
const PURCHASE_ITEMS_URL = `${API_URL}/purchase-items`


export const getPurchaseItems = (query?: string): Promise<{result: PurchaseItem[], resultCount: number}> => {
  return axios
    .get(`${PURCHASE_ITEMS_URL}?${query}`)
    .then((resp: AxiosResponse<{result: PurchaseItem[], resultCount: number}>) => resp.data)
}

export const updatePurchaseItem = (uid: string, body:any): Promise<PurchaseItem> => {
  return axios
    .put(`${PURCHASE_ITEMS_URL}/${uid}`, body)
    .then((resp: AxiosResponse<PurchaseItem>) => resp.data)
}


export const updatePurchaseItemFiscalData = (
  uid: string,
  body: {
    taxReceiptNumber: number | string;
    taxReceiptItemCode: number | string;
  }): Promise<void> => {
  return axios
    .put(`${PURCHASE_ITEMS_URL}/${uid}/tax-receipt`, {
      provider_invoice_number: body.taxReceiptNumber,
      provider_invoice_item_code: body.taxReceiptItemCode
    })
    .then((resp: AxiosResponse<void>) => resp.data)
}
