import axios, {AxiosResponse} from 'axios';
import {OrderItem} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const ORDERS_ITEMS_URL = `${API_URL}/order-items`;

export const getOrdersItems = (query?: string): Promise<OrderItem[]> => {
  return axios
    .get(`${ORDERS_ITEMS_URL}?${query}`)
    .then((resp: AxiosResponse<OrderItem[]>) => resp.data);
};

export const updateCanceledOrderItemReasons = (
  orderItemUid: string,
  body: {
    cancelation_reasons: string;
    cancelation_reasons_details?: string;
  }
): Promise<OrderItem> => {
  return axios
    .put(`${ORDERS_ITEMS_URL}/${orderItemUid}/change-cancel-reasons`, body)
    .then((resp: AxiosResponse<OrderItem>) => resp.data);
};

export const reportOutOfStockItemsOnDelivery = (body: {
  deliveryUid: string;
  outOfStockReason: string;
  orderItemParameters: {uid: string; totalQuantity: number}[];
}): Promise<OrderItem> => {
  return axios
    .post(`${ORDERS_ITEMS_URL}/out-of-stock-delivery-items`, body)
    .then((resp: AxiosResponse<OrderItem>) => resp.data);
};

export const reportPurchaseOutOfStock = (body: {
  referenceId: number;
  orderItemUids: string[];
}): Promise<OrderItem> => {
  return axios
    .put(`${ORDERS_ITEMS_URL}/pending-out-of-stock-resolution`, body)
    .then((resp: AxiosResponse<OrderItem>) => resp.data);
};
