import {FC} from 'react';
import clsx from 'clsx';
import {KTSVG} from '../../../helpers';
import {HeaderUserMenu} from '../../../partials';
import {useLayout} from '../../core';
import Avatar from 'app/shared/components/Avatar';
import {useAuth} from 'app/modules/auth';

const itemClass = 'ms-1 ms-lg-3';
/* btnClass =
    'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-40px',
  btnIconClass = 'svg-icon-1'; */

const Topbar: FC = () => {
  const {config} = useLayout();
  const {currentUser} = useAuth();

  return (
    <div className='d-flex align-items-stretch justify-self-end flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', itemClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', itemClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <p className='fw-semibold m-0 me-10 fs-6'>
          {currentUser?.user?.account_user?.[0]?.account?.name}
        </p>
        <div
          className='cursor-pointer'
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <Avatar
            mainWidth={35}
            avatarWidth={35}
            avatarTextTypology='6'
            name={currentUser?.user?.account_user[0]?.account?.name || ''}
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div
          className='d-flex align-items-center d-lg-none ms-2 me-n3'
          title='Show header menu'
        >
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export {Topbar};
