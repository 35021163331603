import {
  Delivery,
  DeliveryResponsibleTypes,
  DeliveryRouteTypes,
  EstimatedDeliveryTimeTypes,
  LogisticsServiceTypes,
  Order,
  OrderItem,
  PurchaseItemDelivery,
  PurchaseRequest,
} from 'app/models';
import {ProviderPaymentMethodTypes} from './PaymentMethod';

export type PurchaseItem = {
  id: number;
  uid: string;
  is_active?: boolean;
  estimated_delivery_time?: string;
  total_quantity: number;
  total_quantity_canceled: number;
  price: number;
  purchase_id: number;
  purchase: Purchase;
  order_item_id: number;
  order_item: OrderItem;
  deliveries?: PurchaseItemDelivery[];
  provider_invoice_item_code?: string;
  provider_invoice_number?: string;
  provider_invoice_ncm?: string;
  provider_invoice_cfop?: string;
  provider_invoice_cst?: string;
  has_matched_tax_receipt: boolean;
};

export type Purchase = {
  id: number;
  uid: string;
  created_at: Date | string;
  updated_at: Date | string;
  status: string;
  provider_full_address: string;
  delivery_type: string;
  provider_uid: string;
  provider_name: string;
  provider_receipt_reference?: string;
  payment_method: string;
  purchase_request?: PurchaseRequest;
  order?: Order;
  purchase_items: PurchaseItem[];
  comments?: string;
  deliveries?: Delivery[];
  warehouse_uid?: string;
  warehouse_name?: string;
};

export type CreateNewPurchaseRequestData = {
  delivery_route: string;
  provider_uid: string;
  warehouse_uid: string;
  provider_receipt_reference?: string;
  payment_method: string;
  first_leg_logistics_data?: {
    delivery_responsible: string;
    delivery_due_date_at: string;
    logistics_price: number;
    tracking_link: string;
  };
  client_account_uid: string;
  purchase_request_uid: string;
  purchase_items: NewPurchaseItem[];
  comments: string;
};

export type RegisterPurchaseRequestData = {
  client_account_uid: string;
  comments?: string;
  delivery_route: DeliveryRouteTypes;
  logistics_service_type: LogisticsServiceTypes;
  payment_method: ProviderPaymentMethodTypes;
  provider_receipt_reference?: string;
  provider_uid: string;
  warehouse_uid: string;
  purchase_items: Array<{
    delivery_type: EstimatedDeliveryTimeTypes;
    order_item_uid: string;
    price: number;
    total_quantity: number;
    original_stock_item_id?: number;
  }>;
  first_leg_delivery_data?: {
    delivery_responsible: DeliveryResponsibleTypes;
    tracking_link?: string;
    logistics_price: number;
    delivery_due_date_at: string;
  };
};

export type NewPurchaseItem = {
  order_item_uid: string;
  price: number;
  total_quantity: number;
  delivery_type: string;
};

export type EditPurchaseRequestData = {
  payment_method?: string;
  provider_uid?: string;
  warehouse_uid?: string;
  provider_receipt_reference?: string;
  comments?: string;
  purchase_items?: {uid: string; price: number}[];
};

export const PurchasePaymentMethodDictionary: {[key: string]: string} = {
  billed: 'Faturado',
  pix: 'Pix',
  credit_card: 'Cartão de Crédito',
  transfer: 'Transferência Bancária',
  incident_credit: 'Crédito de Devolução',
  incident_credit_and_billed: 'Crédito de Devolução + Faturado',
  incident_credit_and_pix: 'Crédito de Devolução + Pix',
  incident_credit_and_credit_card: 'Crédito de Devolução + Cartão',
  incident_credit_and_transfer: 'Crédito de Devolução + Transferência',
};

export type SelectedPurchaseItemContextProps = {
  state: PurchaseItem;
  updateState: (updates: Partial<PurchaseItem>) => void;
};

export type SelectedPurchaseContextProps = {
  state: Purchase;
  updateState: (updates: Purchase) => void;
};
