import {cleanPhoneNumber} from './formatting';

export const isValidEmailFormat = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const isValidPhoneFormat = (phone: string) => {
  if (phone) {
    const cleanPhone = cleanPhoneNumber(phone);

    if (cleanPhone.length < 13) {
      return false;
    }
    return true;
  }
  return true;
};

export const cnpjValidation = (cnpj: string) => {
  const firstDigitMultiply = [6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9];
  const secondDigitMultiply = [5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9];

  const cnpjAsArray = Array.from(cnpj.replace(/[^0-9]/g, ''));
  const cleanCNPJ = cnpjAsArray.map((digit) => parseInt(digit));

  let firstDigitTotal = 0;
  let secondDigitTotal = 0;

  const firstDigit = cleanCNPJ.at(-2);
  const secondDigit = cleanCNPJ.at(-1);

  for (let i = 0; i < 12; i++) {
    const firstMultiply = cleanCNPJ[i] * firstDigitMultiply[i];

    firstDigitTotal = firstDigitTotal + firstMultiply;
  }

  for (let i = 0; i < 13; i++) {
    const secondMultiply = cleanCNPJ[i] * secondDigitMultiply[i];

    secondDigitTotal = secondDigitTotal + secondMultiply;
  }

  const checkZero = (total: number) => {
    let module = total % 11;
    if (module >= 10) {
      return 0;
    }
    return module;
  };

  const firstVerificationModule = checkZero(firstDigitTotal);
  const secondVerificationModule = checkZero(secondDigitTotal);

  if (
    firstVerificationModule !== firstDigit ||
    secondVerificationModule !== secondDigit
  ) {
    return false;
  }
  return true;
};
