import axios, {AxiosResponse} from 'axios';
import {QuoteRequestItem} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const QUOTE_REQUEST_ITEM_GROUPS_API_URL = `${API_URL}/quote-request-item-groups`;
const QUOTE_REQUEST_ITEM_GROUPS_API_URL_V2 = `${API_URL}/v2/quote-request-item-groups`;

export const getQuoteRequestItemGroups = (
  query?: string
): Promise<{
  quote_request_item_groups: any[];
  resultCount: number;
}> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEM_GROUPS_API_URL}?${query}`)
    .then(
      (
        d: AxiosResponse<{
          quote_request_item_groups: any[];
          resultCount: number;
        }>
      ) => d.data
    )
    .catch((e) => {
      throw e;
    });
};

export const showQuoteRequestItemGroup = (
  quoteRequestItemUid: string
): Promise<any> => {
  return axios
    .get(
      `${QUOTE_REQUEST_ITEM_GROUPS_API_URL}/${quoteRequestItemUid}?includeRelations=true`
    )
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const transferQuoteRequestItemGroup = (
  quoteRequestItemGroupUid: string,
  destination_account_user_uid?: string | null
): Promise<any> => {
  return axios
    .put(
      `${QUOTE_REQUEST_ITEM_GROUPS_API_URL}/${quoteRequestItemGroupUid}/transfer`,
      {destination_account_user_uid}
    )
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const pullQuoteRequestItemGroupFromQueue = (): Promise<any> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEM_GROUPS_API_URL}/pull`)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const listWorkableQuoteRequestItemGroups = (): Promise<any[]> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEM_GROUPS_API_URL}/workable`)
    .then((resp: AxiosResponse<any[]>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const countWorkableQuoteRequestItemGroups = (): Promise<{
  workable_group_counter: number;
}> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEM_GROUPS_API_URL_V2}/workable`)
    .then(
      (
        resp: AxiosResponse<{
          workable_group_counter: number;
        }>
      ) => resp.data
    )
    .catch((e) => {
      throw e;
    });
};

export const updateQuoteRequestItemGroupStatus = (
  quoteRequestItemGroupUid: string,
  body: Array<{quote_request_item_uid: string; status: string}>
): Promise<{
  message: string;
  quoteRequestItemGroup: any;
  quoteRequestItems: QuoteRequestItem[];
}> => {
  return axios
    .put(
      `${QUOTE_REQUEST_ITEM_GROUPS_API_URL}/${quoteRequestItemGroupUid}/status`,
      body
    )
    .then(
      (
        d: AxiosResponse<{
          message: string;
          quoteRequestItemGroup: any;
          quoteRequestItems: QuoteRequestItem[];
        }>
      ) => d.data
    )
    .catch((e) => {
      throw e;
    });
};
