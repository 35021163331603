import { InvoiceEntry} from 'app/models/Invoice';
import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const INVOICE_ENTRY_API_URL = `${API_URL}/banks/invoice-entry`;

export const getInvoiceEntries = (
  params: string
): Promise<{result: InvoiceEntry[] | [], resultCount: number}> => {
  return axios
    .get(`${INVOICE_ENTRY_API_URL}?${params}`)
    .then((d: AxiosResponse<{result: InvoiceEntry[] | [],  resultCount: number}>) => d.data)
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};
