import axios, {AxiosResponse} from 'axios';
import {
  GetFactoryCodesByCatalogSearchData,
  GetFactoryCodesByCatalogSearchResponse,
} from 'app/models/GetFactoryCodesByCatalogSearchData';
import {
  NewOfferDetails,
  Offer,
  OfferSearchResponseItem,
  QueryArrItem,
  QuoteRequestItem,
  SearchVehicle,
  ShowVehicleBrandsResponse,
  ShowVehicleModelsResponse,
} from 'app/models';
import {stringifySimpleRequestQuery} from '_metronic/helpers';
const API_URL = process.env.REACT_APP_API_URL;
const CATALOG_API_URL = `${API_URL}/v1/products/search`;
const GET_OFFERS_API_URL = `${API_URL}/v1/offers/search`;
const VEHICLES_API_URL = `${API_URL}/vehicles`;
const VEHICLE_PLATE_API_URL = `${API_URL}/v1/vehicles`;
const LIST_OFFERS_API_URL = `${API_URL}/v1/offers/list`;

type VehicleTranslationBody = {
  vehiclePlate: string;
  vehicleBrand: string;
  vehicleModelYear: string;
  vehicleModel: string;
  vehicleEnginePower: string;
};

export const getFactoryCodesByCatalogSearch = (
  data: GetFactoryCodesByCatalogSearchData
) => {
  return axios
    .get(`${CATALOG_API_URL}`, {
      params: data,
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getOffersRequest = async ({
  quoteItem,
  quote,
  quoteRequestItem,
  client_account_uid,
  searchVehicle,
  origin,
  autoparts,
}: {
  quoteItem: string;
  quote: any;
  quoteRequestItem: QuoteRequestItem | {id: number; uid: string};
  client_account_uid: string;
  searchVehicle?: SearchVehicle;
  origin: string;
  autoparts?: string;
}): Promise<OfferSearchResponseItem> => {
  const {
    manufacturer,
    model,
    version,
    car_power,
    year,
    valve_number,
    fuel,
    horsepower,
    traction,
    transmission,
    body_style,
    size_type,
    number_of_seats,
    doors,
  } = searchVehicle?.searchParams?.vehicle || {};

  const body: {
    params: {
      reference_id: any;
      quote_request_item_uid: string;
      product_description: string;
      client_account_uid: string;
      manufacturer?: string;
      model?: string;
      version?: string;
      car_power?: string;
      year?: string | number;
      valve_number?: string;
      fuel?: string;
      horsepower?: string;
      take: 50;
      origin: string;
      autoparts?: string;
      traction?: string;
      transmission?: string;
      doors?: string;
      number_of_seats?: string;
      body_style?: string;
      size_type?: string;
    };
  } = {
    params: {
      reference_id: quote.reference_id,
      quote_request_item_uid: quoteRequestItem.uid,
      product_description: quoteItem,
      client_account_uid: client_account_uid,
      manufacturer,
      model,
      version,
      car_power,
      year,
      valve_number,
      fuel,
      horsepower,
      take: 50,
      origin,
      traction,
      transmission,
      doors,
      number_of_seats,
      body_style,
      size_type,
    },
  };
  if (autoparts) {
    body.params.autoparts = autoparts;
  }
  return axios
    .get(`${GET_OFFERS_API_URL}`, body)
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const listOffersRequest = async (params: {
  offer_factory_code?: string;
  offer_brand_name?: string;
  autopart_id?: number;
  client_account_uid?: string;
  quantity_per_package?: number;
  origin?: string;
}): Promise<Offer[]> => {
  return axios
    .get(`${LIST_OFFERS_API_URL}`, {
      params,
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getCodesByTerm = (
  searchItems: string[],
  searchVehicle?: SearchVehicle
) => {
  const searchObject: any = {
    product_description: searchItems[0],
    vehicle_brand: '',
    vehicle_model: '',
    vehicle_version: '',
    vehicle_year: '',
  };

  const vehicleObjectTranslation: VehicleTranslationBody = {
    vehiclePlate: 'vehicle_plate',
    vehicleBrand: 'vehicle_brand',
    vehicleModelYear: 'vehicle_year',
    vehicleModel: 'vehicle_model',
    vehicleEnginePower: 'vehicle_version',
  };

  if (searchVehicle) {
    for (const key in vehicleObjectTranslation) {
      if (searchVehicle[key as keyof SearchVehicle]) {
        searchObject[
          vehicleObjectTranslation[
            key as keyof VehicleTranslationBody
          ] as keyof GetFactoryCodesByCatalogSearchData
        ] = searchVehicle[key as keyof SearchVehicle];
      }
    }
  }

  return axios
    .post(`${API_URL}/catalogs/search`, searchObject)
    .then(
      (d: AxiosResponse<GetFactoryCodesByCatalogSearchResponse[]>) => d.data
    );
};

export const getVehicleByPlate = async (vehiclePlate: {
  [key: string]: string;
}) => {
  return axios
    .get(`${VEHICLE_PLATE_API_URL}/plate/${vehiclePlate.plate}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const newGetOfferDetails = ({
  product_id,
  information_origin_tier,
}: {
  product_id: string;
  information_origin_tier?: string;
}): Promise<NewOfferDetails> => {
  return axios
    .get(
      `${API_URL}/v1/product/details?product_id=${product_id}${
        information_origin_tier
          ? `&information_origin_tier=${information_origin_tier}`
          : ''
      }`
    )
    .then((d: AxiosResponse<NewOfferDetails>) => d.data);
};

export const showVehicleManufacturers = (
  queryArr: QueryArrItem[]
): Promise<ShowVehicleBrandsResponse[]> => {
  const query = stringifySimpleRequestQuery(queryArr);

  return axios
    .get(`${VEHICLES_API_URL}/manufacturers/find?${query}`)
    .then((d: AxiosResponse<ShowVehicleBrandsResponse[]>) => d.data);
};

export const showVehicleModelsByManufacturers = (
  manufacturerId: number,
  queryArr: QueryArrItem[]
): Promise<ShowVehicleModelsResponse[]> => {
  const query = stringifySimpleRequestQuery(queryArr);

  return axios
    .get(
      `${VEHICLES_API_URL}/models/find?manufacturer_id=${manufacturerId}&${query}`
    )
    .then((d: AxiosResponse<ShowVehicleModelsResponse[]>) => d.data);
};

export const showVehicleYearsByModel = (
  modelId: number
): Promise<{description: number; id: number[]}[]> => {
  return axios
    .get(`${VEHICLES_API_URL}/years/find?model_id=${modelId}`)
    .then((d: AxiosResponse<{description: number; id: number[]}[]>) => d.data);
};

export const showVehicleVersionsByModelAndYear = (
  queryArr: QueryArrItem[]
): Promise<{id: number; description: string; vehicle: any}[]> => {
  const query = queryArr ? stringifySimpleRequestQuery(queryArr) : '';

  return axios
    .get(`${VEHICLES_API_URL}/engines/find?${query}`)
    .then(
      (d: AxiosResponse<{id: number; description: string; vehicle: any}[]>) =>
        d.data
    );
};
