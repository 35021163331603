import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AuthContextState,
  AuthContextType,
  AuthenticationSteps,
} from '../Interfaces/Auth';

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({children}: AuthProviderProps) {
  const MAX_AUTH_ATTEMPTS = 3;

  const [state, setAuthContext] = useState<Partial<AuthContextState>>({
    user: null,
    public_key: '',
    redirect: '',
    session: undefined,
    exceededAttempts: false,
    isAuthenticated: false,
    allowedAttempts: MAX_AUTH_ATTEMPTS,
    authStep: AuthenticationSteps.GET_OTP_CODE,
  });

  const updateAuthContext = useCallback(
    (params: Partial<AuthContextState>) =>
      setAuthContext({...state, ...params}),
    [state]
  );

  useEffect(() => {
    const isAuthenticated = true;
    updateAuthContext({isAuthenticated});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: state.user || null,
        allowedAttempts: state.allowedAttempts || 3,
        isAuthenticated: state.isAuthenticated || false,
        exceededAttempts: state.exceededAttempts || false,
        authStep: state.authStep || AuthenticationSteps.INTERNAL_AUTH,
        redirect: state.redirect,
        session: state.session,
        public_key: state.public_key,
        updateAuthContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
