import axios, {AxiosResponse} from 'axios';
import {
  Offer,
  Quote,
  QuoteRequestItem,
  ToggleQuoteOfferResponse,
} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const QUOTE_REQUEST_ITEMS_API_URL = `${API_URL}/quote-request-items`;

export const getQuoteRequestItems = (
  query?: string
): Promise<{result: QuoteRequestItem[]; resultCount: number}> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEMS_API_URL}?${query}`)
    .then(
      (d: AxiosResponse<{result: QuoteRequestItem[]; resultCount: number}>) =>
        d.data
    )
    .catch((e) => {
      throw e;
    });
};

export const showQuoteRequestItem = (
  quoteRequestItemUid: string
): Promise<QuoteRequestItem> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEMS_API_URL}/${quoteRequestItemUid}`)
    .then((d: AxiosResponse<QuoteRequestItem>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const updateQuoteRequestItemStatus = (
  quoteRequestItemUid: string,
  status: string
): Promise<{
  message: string;
  quoteRequestItem: QuoteRequestItem;
  quote: Quote;
}> => {
  return axios
    .put(`${QUOTE_REQUEST_ITEMS_API_URL}/${quoteRequestItemUid}/status`, {
      status,
    })
    .then(
      (
        d: AxiosResponse<{
          message: string;
          quoteRequestItem: QuoteRequestItem;
          quote: Quote;
        }>
      ) => d.data
    )
    .catch((e) => {
      throw e;
    });
};

export const pullQuoteRequestItemFromQueue = (): Promise<QuoteRequestItem> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEMS_API_URL}/pull`)
    .then((d: AxiosResponse<QuoteRequestItem>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const listWorkableQuoteRequestItems = (): Promise<
  QuoteRequestItem[]
> => {
  return axios
    .get(`${QUOTE_REQUEST_ITEMS_API_URL}/workable`)
    .then((resp: AxiosResponse<QuoteRequestItem[]>) => resp.data);
};

export const createNewQuoteRequestItem = (body: {
  description: string;
  quote_request_id: number;
}): Promise<QuoteRequestItem[]> => {
  return axios
    .post(`${QUOTE_REQUEST_ITEMS_API_URL}`, body)
    .then((resp: AxiosResponse<QuoteRequestItem[]>) => resp.data);
};

export const toggleQuoteItemOffer = (
  quoteRequestItemUid: string,
  offer: Offer
): Promise<any> => {
  return axios
    .post(
      `${QUOTE_REQUEST_ITEMS_API_URL}/${quoteRequestItemUid}/quote-offers`,
      offer
    )
    .then((d: AxiosResponse<ToggleQuoteOfferResponse>) => d.data?.quote)
    .catch((e) => {
      throw e;
    });
};
